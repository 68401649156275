// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactsStep_formContainer__1Kh5M {
  padding-bottom: 24px;
}
.ContactsStep_formContainer__1Kh5M .ContactsStep_input_experience__AHGMd {
  width: 50%;
}
.ContactsStep_formContainer__1Kh5M .ContactsStep_hr__YtjwA {
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
}
.ContactsStep_formContainer__1Kh5M .ContactsStep_wrapInputName__0Eeu1 {
  display: flex;
}
.ContactsStep_formContainer__1Kh5M .ContactsStep_fullName__VsOep {
  width: 100%;
  color: black;
  font-size: 16px;
  margin: 0 4px 24px 0px;
}`, "",{"version":3,"sources":["webpack://./src/Components/CvSteps/ContactsStep/ContactsStep.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AACE;EACE,UAAA;AACJ;AAEE;EACE,yBAAA;EACA,yBAAA;AAAJ;AAEE;EACE,aAAA;AAAJ;AAEE;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,sBAAA;AAAJ","sourcesContent":[".formContainer {\n  padding-bottom: 24px;\n\n  .input_experience {\n    width: 50%;\n  }\n\n  .hr {\n    border: 1px solid #e0e0e0;\n    background-color: #e0e0e0;\n  }\n  .wrapInputName {\n    display: flex;\n  }\n  .fullName {\n    width: 100%;\n    color: black;\n    font-size: 16px;\n    margin: 0 4px 24px 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formContainer": `ContactsStep_formContainer__1Kh5M`,
	"input_experience": `ContactsStep_input_experience__AHGMd`,
	"hr": `ContactsStep_hr__YtjwA`,
	"wrapInputName": `ContactsStep_wrapInputName__0Eeu1`,
	"fullName": `ContactsStep_fullName__VsOep`
};
export default ___CSS_LOADER_EXPORT___;
