import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ContactsStep from "../../Components/CvSteps/ContactsStep/ContactsStep";
import EducationStep from "../../Components/CvSteps/EducationStep/EducationStep";
import ExperienceStep from "../../Components/CvSteps/ExperienceStep/ExperienceStep";
import SkillsStep from "../../Components/CvSteps/SkillsStep/SkillsStep";
import SummaryStep from "../../Components/CvSteps/SummaryStep/SummaryStep";
import PreviewStep from "../../Components/CvSteps/PreviewStep/PreviewStep";
import Download from "../../Components/Download/Download";
import LeftStepper from "../../Components/LeftStepper/LeftStepper";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorModal from "../../Components/errorModal/errorModal";
import CertificationStep from "../../Components/CvSteps/CertificationStep/CertificationStep";
import { onRefreshToken } from "../../utils/auth.utils";
import { useDispatch, useSelector } from "react-redux";
import { completeCvStep, getCvOptions, setDownloadCvEditId, setErrorModal, setPdfCvHref } from "../../redux/reducers/cvReducer";

const Multiform = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const errorModal = useSelector((state) => state.cvReducer.errorModal);
  const errorText = useSelector((state) => state.cvReducer.errorText);
  const isLoading = useSelector((state) => state.cvReducer.isLoading);
  const [pageNum, setPageNum] = useState({ name: 1 });
  const [achievements, setAchievements] = useState("");
  const [contacts, setContacts] = useState({});
  const [education, setEducation] = useState([]);
  const [certification, setCertification] = useState([]);
  const [experience, setExperience] = useState([]);
  const [skills, setSkills] = useState({});
  const pdfCv = useSelector((state) => state.cvReducer.pdfCvHref);
  const [previousContacts, setPreviousContacts] = useState({});
  const [previousEducation, setPreviousEducation] = useState({});
  const [previousCertification, setPreviousCertification] = useState({});
  const [previousExperience, setPreviousExperience] = useState({});
  const [previousSkills, setPreviousSkills] = useState({});
  const [prLanguagesData, setPrLanguagesData] = useState([]);
  const [industryData, setIndustryData] = useState([]);
  const [degreeData, setDegreeData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [edit, setEdit] = useState(false);
  const utc = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
  const { name, sub } = JSON.parse(localStorage.getItem("user"));

  const handleGetCvOptions = async () => {
    const result = await dispatch(getCvOptions(onRefreshToken));
    const resultData = result.payload;
    setPrLanguagesData(resultData[0].data.items.map((item) => item.name));
    setIndustryData(resultData[1].data.items.map((item) => item.name));
    setCountryData(resultData[2].data.items.map((item) => item));
    setJobData(resultData[3].data.items.map((item) => item.name));
    setLanguageData(resultData[4].data.items.map((item) => item));
    setDegreeData(resultData[5].data.items.map((item) => item.name));
  };

  useEffect(() => {
    handleGetCvOptions();
  }, []);

  useEffect(() => {
    if (history.location.state && history.location.state.addNewCV) {
      setPageNum({ name: 1 });
      setAchievements("");
      setContacts({});
      setExperience([]);
      setSkills({});
      dispatch(setPdfCvHref(""))
      setPreviousContacts({});
      setPreviousEducation([
        {
          school_name: "",
          degree: "",
          field_of_studies: "",
          graduate_date_month: "",
          graduate_date_year: "",
        },
      ]);
      setPreviousCertification([
        {
          certificate_name: "",
          description: "",
          date_of_obtaining_month: "",
          date_of_obtaining_year: "",
        },
      ]);
      setPreviousExperience([
        {
          name: "",
          company: "",
          under_nda: false,
          industry: "",
          country: null,
          job_title: "",
          start_date_month: "",
          start_date_year: "",
          end_date_month: "",
          end_date_year: "",
          currently_work_here: false,
          description: "",
          responsibilities: "",
          achievements: "",
          tools_and_technologies: "",
        },
      ]);
      setPreviousSkills({});
    } else if (history.location.state && history.location.state.edit) {
      const data = history.location.state.edit;
      setPageNum({ name: 1 });
      if (true) {
        setEdit(true);
        dispatch(setDownloadCvEditId(history.location.state.edit.id));
        setAchievements(data.summary);

        setContacts({
          name: data.name,
          country: data.country,
          job_title: data.job_title,
          years_of_experience: data.years_of_experience,
          languages: data.languages,
          months_of_experience: data.months_of_experience,
        });

        const newEducationObj = data?.educations?.map((obj) => {
          let object = {
            id: obj.id,
            school_name: obj.school_name,
            degree: obj.degree,
            field_of_studies: obj.field_of_studies,
            graduate_date_month: obj.graduate_date_month,
            graduate_date_year: obj.graduate_date_year,
          };
          return object;
        });
        setEducation(newEducationObj);
        const newCertificationObj = data?.certifications?.map((obj) => {
          let object = {
            id: obj.id,
            certificate_name: obj.certificate_name,
            description: obj.description,
            date_of_obtaining_month: obj.date_of_obtaining_month,
            date_of_obtaining_year: obj.date_of_obtaining_year,
          };
          return object;
        });
        setCertification(newCertificationObj);
        const newExperienceObj =
          data && data.projects.length
            ? data?.projects?.map((obj) => {
                let object = {
                  id: obj.id,
                  cv_id: obj.cv_id,
                  name: obj?.name || "",
                  company: obj.company,
                  under_nda: obj.under_nda,
                  industry: obj.industry,
                  country: obj?.country,
                  job_title: obj.job_title,
                  start_date_month: obj.start_date_month,
                  start_date_year: obj.start_date_year,
                  end_date_month: obj.end_date_month,
                  end_date_year: obj.end_date_year,
                  currently_work_here: obj.currently_work_here,
                  description: obj.description,
                  responsibilities: obj.responsibilities,
                  achievements: obj.achievements,
                  tools_and_technologies: obj.tools_and_technologies,
                };
                return object;
              })
            : [];
        setExperience(newExperienceObj);
        setSkills({
          skills: data.skills,
          extra_skills: data.extra_skills,
          bug_tracking_and_pm: data.bug_tracking_and_pm,
          devops_skills: data.devops_skills,
          design_patterns: data.design_patterns,
          other_skills: data.other_skills,
        });
        dispatch(setPdfCvHref(""))
        setPreviousContacts({
          fullName: data.name,
          country: data.country,
          job_title: data.job_title,
          experience: data.years_of_experience,
          experienceMonth: data.months_of_experience,
          languages: data.languages,
        });

        const newPreviousEducationObj = data?.educations?.map((obj) => {
          let object = {
            id: obj.id,
            degree: obj.degree,
            graduate_date_month: obj.graduate_date_month,
            graduate_date_year: obj.graduate_date_year,
            school_name: obj.school_name,
            field_of_studies: obj.field_of_studies,
          };
          return object;
        });

        setPreviousEducation(newPreviousEducationObj);
        const newPreviousCertificationObj = data?.certifications?.map((obj) => {
          let object = {
            id: obj.id,
            certificate_name: obj.certificate_name,
            description: obj.description,
            date_of_obtaining_month: obj.date_of_obtaining_month,
            date_of_obtaining_year: obj.date_of_obtaining_year,
          };
          return object;
        });

        setPreviousCertification(newPreviousCertificationObj);
        const newPreviousExperienceObj = data?.projects?.map((obj) => {
          let object = {
            id: obj.id,
            cv_id: obj.cv_id,
            job_title: obj.job_title,
            name: obj.name,
            company: obj.company,
            under_nda: obj.under_nda,
            industry: obj.industry,
            country: obj.country,
            start_date_month: obj.start_date_month,
            start_date_year: obj.start_date_year,
            end_date_month: obj.end_date_month,
            end_date_year: obj.end_date_year,
            currently_work_here: obj.currently_work_here,
            description: obj.description,
            responsibilities: obj.responsibilities,
            achievements: obj.achievements,
            tools_and_technologies: obj.tools_and_technologies,
          };
          return object;
        });

        setPreviousExperience(newPreviousExperienceObj);
        setPreviousSkills({
          general_info: data.general_info,
          skills: data.skills,
          frameworks: data.extra_skills,
          bugs: data.bug_tracking_and_pm,
          cvs: data.devops_skills,
          patterns: data.design_patterns,
          other_skills: data.other_skills,
        });
      }
    }
  }, [
    history.location,
    prLanguagesData,
    industryData,
    degreeData,
    countryData,
    languageData,
    jobData,
  ]);

  const data = {
    name: contacts.name ? contacts.name : "",
    languages: contacts.languages ? contacts.languages : [],
    country: contacts.country ? contacts.country : [],
    job_title: contacts.job_title ? contacts.job_title : "",
    years_of_experience: contacts.years_of_experience ? contacts.years_of_experience : null,
    months_of_experience: contacts.months_of_experience ? contacts.months_of_experience : null,
    summary: achievements ? achievements : "",
    bug_tracking_and_pm: skills.bug_tracking_and_pm ? skills.bug_tracking_and_pm : "",
    devops_skills: skills.devops_skills ? skills.devops_skills : "",
    extra_skills: skills.extra_skills ? skills.extra_skills : "",
    design_patterns: skills.design_patterns ? skills.design_patterns : "",
    general_info: skills.general_info,
    skills: skills.skills ? skills.skills : [],
    other_skills: skills.other_skills ? skills.other_skills : "",
    educations: education,
    certifications: certification,
    projects: experience,
    created_at: utc,
    creator_name: name,
    creator_id: sub,
  };

  let downloadFile = () => {
    const aElement = document.createElement("a");
    aElement.setAttribute("download", `${data.name.replaceAll(" ", "_")}.pdf`);
    aElement.href = pdfCv;
    aElement.setAttribute("target", "_blank");
    aElement.click();
    URL.revokeObjectURL(pdfCv);
  };

  let updateData = (value) => {
    setPageNum({ name: value });
    value === 2 && dispatch(completeCvStep(1, true)); // set first step to completed
    dispatch(completeCvStep(value, true));
  };
  let achievementsHandler = (value) => {
    setAchievements(value);
  };
  const handleContacts = (data) => {
    setContacts(data);
  };

  const handleEducation = (data) => {
    setEducation(data);
  };
  const handleCertification = (data) => {
    setCertification(data);
  };
  const handleExperience = (data) => {
    setExperience(data);
  };
  const handleSkills = (data) => {
    setSkills(data);
  };
  const handlePreviousContacts = (data) => {
    setPreviousContacts(data);
  };
  const handlePreviousEducation = (data) => {
    setPreviousEducation(data);
  };
  const handlePreviousCertification = (data) => {
    setPreviousCertification(data);
  };
  const handlePreviousExperience = (data) => {
    setPreviousExperience(data);
  };
  const handlePreviousSkills = (data) => {
    setPreviousSkills(data);
  };
  return (
    <div className="main-container">
      <div className="container">
        {errorModal && (
          <ErrorModal
            onOpen={errorModal}
            onClose={() => dispatch(setErrorModal(false))}
            text={errorText}
          />
        )}
        {isLoading ? (
          <div className="isLoading">
            <CircularProgress />
          </div>
        ) : (
          <div className="subcontainer">
            {pageNum.name !== 8 && <LeftStepper step={pageNum.name - 1} updateData={updateData} />}
            {pageNum.name === 1 && (
              <ContactsStep
                updateData={updateData}
                handleContacts={handleContacts}
                handlePreviousContacts={handlePreviousContacts}
                previousContacts={previousContacts}
                countryData={countryData}
                jobData={jobData}
                languageData={languageData}
              />
            )}
            {pageNum.name === 2 && (
              <EducationStep
                updateData={updateData}
                handleEducation={handleEducation}
                handlePreviousEducation={handlePreviousEducation}
                previousEducation={previousEducation}
                degreeData={degreeData}
              />
            )}
            {pageNum.name === 3 && (
              <CertificationStep
                updateData={updateData}
                handleCertification={handleCertification}
                handlePreviousCertification={handlePreviousCertification}
                previousCertification={previousCertification}
              />
            )}
            {pageNum.name === 4 && (
              <ExperienceStep
                updateData={updateData}
                handleExperience={handleExperience}
                handlePreviousExperience={handlePreviousExperience}
                previousExperience={previousExperience}
                industryData={industryData}
                countryData={countryData}
                jobData={jobData}
              />
            )}
            {pageNum.name === 5 && (
              <SkillsStep
                updateData={updateData}
                handleSkills={handleSkills}
                handlePreviousSkills={handlePreviousSkills}
                previousSkills={previousSkills}
                prLanguagesData={prLanguagesData}
              />
            )}
            {pageNum.name === 6 && (
              <SummaryStep
                updateData={updateData}
                achievementsHandler={achievementsHandler}
                data={achievements}
              />
            )}
            {pageNum.name === 7 && (
              <PreviewStep updateData={updateData} data={achievements} edit={edit} allData={data}/>
            )}
            {pageNum.name === 8 && <Download pdfCv={pdfCv} handleDownload={downloadFile} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Multiform;
