import React from "react";
import {
  IconButtonBottomStyled,
  IconButtonStyled,
  KeyboardArrowDownOutlinedIconStyled,
  KeyboardArrowUpOutlinedIconStyled,
} from "./ScrollButtons.module";
import useScrollButtons from "../../../hooks/useScrollButtons";

const ScrollButtons = () => {
  const { scrollBtnUp, scrollBtnDown, scrollToTop, scrollToBottom } = useScrollButtons();

  return (
    <>
      {scrollBtnUp && (
        <IconButtonStyled onClick={scrollToTop} size="large">
          <KeyboardArrowUpOutlinedIconStyled fontSize="inherit" />
        </IconButtonStyled>
      )}
      {scrollBtnDown && (
        <IconButtonBottomStyled onClick={scrollToBottom} size="large">
          <KeyboardArrowDownOutlinedIconStyled fontSize="inherit" />
        </IconButtonBottomStyled>
      )}
    </>
  );
};

export default ScrollButtons;
