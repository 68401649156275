import { z } from "zod";

// if validation is needed - uncomment
const certificationSchema = z.object({
  certificate_name: z.string().optional().nullable(), //.min(1, "Certificate name is required"),
  description: z.string().optional().nullable(), //.min(1, "Description is required"),
  date_of_obtaining_month: z.string().optional().nullable(), //.min(1, "Date of obtaining month is required"),
  date_of_obtaining_year: z.string().optional().nullable(), //.min(1, "Date of obtaining year is required"),
}).optional().nullable();

export const certificationFormSchema = z.object({
  certifications: z.array(certificationSchema).optional(),
}).optional();
