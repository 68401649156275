import { getFlag } from "../common.utils";
import { saveAs } from "file-saver";
import { Document, ImageRun, Packer, Paragraph, Table, TableCell, TableRow, TextRun } from "docx";
import {
  getDocumentOptions,
  BlueColor,
  sectionsCfg,
  SkillsTableCellBorders,
  TableLeftCellBorders,
  TableRightCellBorders,
  TextHeight12,
  TextHeightH2,
  TextHeight22,
  TableCellNoBorders,
} from "./downloadDoc.config";
import {
  getBulletsList,
  getHorizontalLine,
  getEmptyLine,
  isEmptyObj,
  getEmptyCell,
  groupProjectsByCompanyAndJobTitle,
  getProjectInfo,
  getMultilineBulletText,
} from "./downloadDoc.helpers";

export const handleDocXDownload = async (cv) => {
  const imageBuffer = await getFlag(cv);
  const doc = new Document({
    ...getDocumentOptions([...getCVContent(cv, imageBuffer)]),
  });
  const blob = await Packer.toBlob(doc);
  saveAs(blob, `${cv.name}.docx`);
};

const getCVContent = (cv, imageBuffer) => {
  let doc = [];
  Object.keys(sectionsCfg).forEach((cfg) => {
    doc = [...doc, ...getParagraphs(cv, sectionsCfg[cfg], imageBuffer)];
  });
  return doc;
};

const getParagraphs = (cv, cfg, imageBuffer) => {
  const data = cv[cfg.type];
  switch (cfg.type) {
    case sectionsCfg.name.type:
      return getHeaderContent(cv, imageBuffer);
    case sectionsCfg.summary.type:
      return getSummaryContent(cv, cfg);
    case sectionsCfg.skills.type:
      return getSkillsContent(cv, cfg);
    case sectionsCfg.projects.type:
      return !isEmptyObj(data[0]) ? getProjectsContent(cv, cfg) : [];
    case sectionsCfg.educations.type:
      return !isEmptyObj(data[0]) ? getEducationsContent(cv, cfg) : [];
    case sectionsCfg.certifications.type:
      return !isEmptyObj(data[0]) ? getCertificationsContent(cv, cfg) : [];
    default:
      return [];
  }
};

const getHeaderContent = (cv, imageBuffer) => [
  getCVName(cv, imageBuffer),
  ...getJobTitle(cv),
  !!cv.languages.length && getLanguages(cv.languages),
  getHorizontalLine(),
];

const getSummaryContent = (cv, cfg) => [
  ...getSectionHeader(cfg),
  ...getPlainText(cv[cfg?.type] || ""),
  getHorizontalLine(),
];

const getSkillsContent = (cv, cfg) => [
  ...getSectionHeader(cfg),
  ...(!cv.general_info
    ? [getSplittedSkillsContentTable(cv, cfg)]
    : [getSkillsTable([cv.general_info]), getHorizontalLine()]),
];

const getProjectsContent = (cv, cfg) => [
  ...getSectionHeader(cfg),
  getProjectsTable(cv?.[cfg.type] || []),
  getEmptyLine(),
  getEmptyLine(),
];

const getEducationsContent = (cv, cfg) => [
  ...getSectionHeader(cfg),
  ...getBulletsList(cfg, cv[cfg.type]),
  getHorizontalLine(),
];

const getCertificationsContent = (cv, cfg) => [
  ...getSectionHeader(cfg),
  ...getBulletsList(cfg, cv[cfg.type]),
];

const getSkillsTable = (skills) => {
  const skillsArr = skills.includes("\n")
    ? skills.split("\n")
    : skills.includes(",")
      ? skills.split(",")
      : skills.includes(" ")
        ? skills.split(" ")
        : [skills || ""];
  const firstCellElements = skillsArr?.slice(0, skillsArr.length / 2);
  const secondCellElements = skillsArr?.slice(skillsArr.length / 2);
  const cellArr =
    firstCellElements.length === 0
      ? [secondCellElements, firstCellElements]
      : [firstCellElements, secondCellElements];

  return new Table({
    width: {
      size: 100,
      type: "pct",
    },
    rows: [
      new TableRow({
        children: cellArr.map((elements) => {
          return new TableCell({
            width: {
              size: 50,
              type: "pct",
            },
            borders: TableCellNoBorders,
            children: getMultilineBulletText(elements),
          });
        }),
      }),
    ],
  });
};

const getSectionHeader = (cfg) => {
  return [
    new Paragraph({
      children: [
        new TextRun({
          text: cfg?.label.toUpperCase() || "",
          size: TextHeight22,
          color: BlueColor,
        }),
      ],
      spacing: {
        after: 100,
      },
    }),
  ];
};

const getCVName = (cv, imageBuffer) => {
  return new Paragraph({
    children: [
      new TextRun({
        text: `${cv?.name || ""}, `,
        bold: true,
        size: TextHeightH2,
      }),
      new ImageRun({
        type: "png",
        data: imageBuffer,
        transformation: {
          width: 20,
          height: 15,
        },
      }),
      new TextRun({
        text: `   ${cv?.country?.name || ""}`,
        size: TextHeight12,
      }),
    ],
  });
};

const getPlainText = (text) => [
  new Paragraph({
    alignment: "both",
    children: [
      new TextRun({
        text: text,
        size: TextHeight12,
      }),
    ],
  }),
];

const getLanguages = (languages) =>
  new Paragraph({
    children: !!languages.length
      ? languages?.map(
          (lang, idx) =>
            new TextRun({
              text: `${lang?.name || ""} ${lang?.code ? `(${lang?.code})` : ""} ${lang?.level ? ` - ${lang?.level}` : ""}${languages?.length - 1 > idx ? ", " : "."}`,
              size: TextHeight12,
            })
        )
      : [],
  });

const getJobTitle = (cv) => [
  new Paragraph({
    children: [
      new TextRun({
        text: `${cv?.job_title || ""}, ${cv?.years_of_experience || ""} years${cv?.months_of_experience ? ` ${cv.months_of_experience} months` : ""} of experience`,
        size: TextHeight12,
      }),
    ],
    spacing: {
      after: 200,
    },
  }),
];

const getProjectsTable = (projectsArr) => {
  const experience = groupProjectsByCompanyAndJobTitle(projectsArr);
  const rows = experience.map(
    (item) =>
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 30,
              type: "pct",
            },
            borders: TableLeftCellBorders,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.company || "",
                    bold: true,
                    size: TextHeight12,
                  }),
                ],
              }),

              new Paragraph({
                children: [
                  new TextRun({
                    text: item?.job_title || "",
                    size: TextHeight12,
                  }),
                ],
              }),

              (item?.projects?.[0]?.start_date_month ||
                item?.projects?.[0]?.start_date_year ||
                item?.projects?.[0]?.end_date_month ||
                item?.projects?.[0]?.end_date_year) &&
                new Paragraph({
                  children: [
                    new TextRun({
                      text: `${item?.projects?.[0]?.start_date_month ? `${item?.projects?.[0]?.start_date_month},` : ""} ${item?.projects?.[0]?.start_date_year ? `${item?.projects?.[0]?.start_date_year} -` : ""} ${item?.projects?.[0]?.currently_work_here ? "currently" : `${item?.projects?.[0]?.end_date_month ? `${item?.projects?.[0]?.end_date_month},` : ""} ${item?.projects?.[0]?.end_date_year || ""}`}`,
                      size: TextHeight12,
                    }),
                  ],
                }),
            ],
          }),
          new TableCell({
            width: {
              size: 70,
              type: "pct",
            },
            borders: TableRightCellBorders,
            children: getProjectInfo(item?.projects),
          }),
        ],
      })
  );

  const tableHeader = new TableRow({
    cantSplit: true,
    tableHeader: true,
    children: [
      new TableCell({
        width: { size: 30, type: "pct" },
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Project Information",
                bold: true,
                size: TextHeight12,
              }),
            ],
          }),
          getEmptyLine(),
        ],
        borders: TableLeftCellBorders,
      }),
      new TableCell({
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: "Details",
                bold: true,
                size: TextHeight12,
              }),
            ],
          }),
          getEmptyLine(),
        ],
        borders: TableRightCellBorders,
      }),
    ],
  });

  return new Table({
    width: {
      size: 100,
      type: "pct",
    },
    rows: [tableHeader, ...rows],
  });
};

const getSplittedSkillsContentTable = (cv, cfg) => {
  const skillsList = cfg.textFieldKeys;
  const cellsArr = [];
  skillsList.forEach(
    //creating array of cells
    (skill) =>
      !!cv[skill].length &&
      cellsArr.push(
        new TableCell({
          width: {
            size: 50,
            type: "pct",
          },
          borders: SkillsTableCellBorders,
          children: [
            getEmptyLine(),
            new Paragraph({
              children: [
                new TextRun({
                  text: `${cfg?.skillsTitles?.[skill]}: `,
                  bold: true,
                  size: TextHeight12,
                }),
              ],
            }),
            ...getMultilineBulletText(cv[skill]),
            getEmptyLine(),
          ],
        })
      )
  );

  const rows = [];
  cellsArr.forEach((cell, idx) => {
    if (idx % 2 === 0) {
      //new row
      rows.push(
        new TableRow({
          children: [cell, cellsArr.length - 1 > idx ? cellsArr[idx + 1] : getEmptyCell()],
        })
      );
    }
  });
  return new Table({
    width: {
      size: 100,
      type: "pct",
    },
    rows: rows,
  });
};
