import {
  AlignmentType,
  BorderStyle,
  Header,
  ImageRun,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from "docx";
import companyLogo from "../../assets/pictures/companyLogo.png";

//Colors
export const ContextSeparatorBorderColor = "cbc8c8";
export const ProjectBorderColor = "cbc8c8";
export const BlueColor = "556EE6";
export const DocumentBackground = "ecf1fe";
export const TextBackground = "ffffff";

//Fonts size
export const TextHeightH2 = 72;
export const TextHeight22 = 44;
export const TextHeight12 = 24;
export const TextHeight10 = 20;

//width
export const FlagWidth = "20";

//borders styling
export const TableLeftCellBorders = {
  left: { style: BorderStyle.NONE, size: 6, color: ProjectBorderColor },
  top: { style: BorderStyle.SINGLE, size: 6, color: ProjectBorderColor },
  bottom: { style: BorderStyle.SINGLE, size: 6, color: ProjectBorderColor },
  right: { style: BorderStyle.SINGLE, size: 6, color: ProjectBorderColor },
};
export const TableRightCellBorders = {
  right: { style: BorderStyle.NONE, size: 6, color: ProjectBorderColor },
  top: { style: BorderStyle.SINGLE, size: 6, color: ProjectBorderColor },
  bottom: { style: BorderStyle.SINGLE, size: 6, color: ProjectBorderColor },
  left: { style: BorderStyle.SINGLE, size: 6, color: ProjectBorderColor },
};
export const SkillsTableCellBorders = {
  left: { style: BorderStyle.NONE, size: 6, color: ProjectBorderColor },
  top: { style: BorderStyle.SINGLE, size: 6, color: ProjectBorderColor },
  bottom: { style: BorderStyle.SINGLE, size: 6, color: ProjectBorderColor },
  right: { style: BorderStyle.NONE, size: 6, color: ProjectBorderColor },
};

export const TableCellNoBorders = {
  right: { style: BorderStyle.NONE, size: 6, color: ProjectBorderColor },
  top: { style: BorderStyle.NONE, size: 6, color: ProjectBorderColor },
  bottom: { style: BorderStyle.NONE, size: 6, color: ProjectBorderColor },
  left: { style: BorderStyle.NONE, size: 6, color: ProjectBorderColor },
};

//mapping helpers config
export const sectionsCfg = {
  name: {
    type: "name",
  },
  summary: {
    type: "summary",
    label: "summary",
  },
  skills: {
    type: "skills",
    label: "skills",
    textFieldKeys: [
      "skills", "extra_skills", "bug_tracking_and_pm", "devops_skills", "design_patterns", "other_skills"
      // ["skills", "extra_skills"],
      // ["bug_tracking_and_pm", "devops_skills"],
      // ["design_patterns", "other_skills"],
    ],
    skillsTitles: {
      skills: "Programming Languages/Technologies",
      extra_skills: "Frameworks/Libraries",
      bug_tracking_and_pm: "Bug Tracking/Project Management",
      devops_skills: "Software/CVS/Build Automation",
      design_patterns: "Design Patterns",
      other_skills: "Others",
    },
  },
  projects: {
    type: "projects",
    label: "experience",
  },
  educations: {
    type: "educations",
    label: "education",
    textFieldKeys: [
      "school_name",
      "degree",
      "field_of_studies",
      "graduate_date_month",
      "graduate_date_year",
    ],
  },
  certifications: {
    type: "certifications",
    label: "certification",
    textFieldKeys: [
      "certificate_name",
      "description",
      "date_of_obtaining_month",
      "date_of_obtaining_year",
    ],
  },
};

//main docX document config
export const getDocumentOptions = (children) => ({
  styles: {
    default: {
      document: {
        run: {
          font: "Red Hat Display",
        },
      },
    },
  },
  background: {
    color: DocumentBackground,
  },
  sections: [
    {
      headers: {
        default: new Header({
          children: [
            new Paragraph({
              alignment: AlignmentType.RIGHT,
              children: [
                new ImageRun({
                  type: "png",
                  data: companyLogo,
                  transformation: {
                    width: 140,
                    height: 25,
                  },
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.RIGHT,
              spacing: {
                before: 100,
                after: 150,
              },
              children: [
                new TextRun({
                  text: "We develop cutting-edge technological and innovative solutions",
                  size: TextHeight10,
                  color: "000000",
                }),
              ],
            }),
          ],
        }),
      },
      properties: {
        page: {
          margin: {
            header: 400,
            top: 300,
            bottom: 700,
            left: 700,
            right: 700,
          },
        },
      },
      children: [
        new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: children,
                  shading: {
                    type: ShadingType.CLEAR,
                    fill: TextBackground,
                  },
                  borders: TableCellNoBorders,
                  margins: {
                    top: 200,
                    bottom: 200,
                    left: 200,
                    right: 200,
                  },
                }),
              ],
            }),
          ],
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
        }),
      ],
    },
  ],
});
