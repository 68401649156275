import { getApiHeader } from "../api/api";
import { onRefreshToken } from "./auth.utils";
const { REACT_APP_API_URL } = process.env;

export const downloadPDFFile = (id, name) => {
  fetch(`${REACT_APP_API_URL}api/v2/cvs/${id}/pdf`, {
    method: "GET",
    headers: getApiHeader(),
  })
    .then((res) => {
      if (res.status === 403) {
        onRefreshToken();
      } else {
        return res.blob();
      }
    })
    .then((res) => {
      const href = URL.createObjectURL(res);
      const aElement = document.createElement("a");
      aElement.setAttribute("download", `${name.replaceAll(" ", "_")}.pdf`);
      aElement.href = href;
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(href);
    })
    .then((res) => {
      return res;
    });
};

export const getPDFData = async (id) => {
  try {
    const res = await fetch(`${REACT_APP_API_URL}api/v2/cvs/${id}/pdf`, {
      method: "GET",
      headers: new Headers(getApiHeader()),
    });

    if (res.status === 403) {
      onRefreshToken();
      return null;
    }

    const blob = await res.blob();
    const href = URL.createObjectURL(blob);
    return href;
  } catch (error) {
    console.error("Error fetching PDF data:", error);
    throw error;
  }
};
