import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PublicRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (!authenticated ? <Component {...props} /> : <Redirect to="/home" />)}
    />
  );
};
