import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import allCvReducer from "./reducers/allCvReducer";
import cvReducer from "./reducers/cvReducer";

const rootReducer = combineReducers({
  allCvReducer,
  cvReducer,
});
const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
export default store;
