import React from "react";
import Tabs from "../../Components/Tab/Tab";
import plusWhite from "../../assets/pictures/plusWhite.png";
import { useHistory } from "react-router-dom";

export const Home = () => {
  let isLogged = false;
  const history = useHistory();

  const onToggleAddResume = () => {
    history.push("/steps");
  };
  return (
    <div style={{height: "87vh"}}>
      {!isLogged && (
        <>
          <Tabs />
          <div className="center">
            <p
              style={{
                fontSize: "24px",
                fontWeight: "500",
                lineHeight: "32.02px",
                color: "#333333",
                letterSpacing: "0.15px",
                marginBottom: "13px",
              }}>
              Let&apos;s get you a resume!
            </p>
            <p
              style={{
                width: "467px",
                fontWeight: "400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#333333",
                letterSpacing: "0.15px",
                marginBottom: "24px",
              }}>
              There is no resume currently associated to you. When you create one it will show up
              here for you to manage.
            </p>
            <button
              onClick={onToggleAddResume}
              className="add-cv-btn"
              type="text">
              <img
                className="img-plus"
                alt="logo"
                width="16.67px"
                height="16.67px"
                src={plusWhite}
              />
              Add a New Resume
            </button>
          </div>
        </>
      )}
    </div>
  );

  // return (
  //   <>
  //     <div className={styles.tabs}>
  //       <Tabs />
  //     </div>
  //
  //     <div className={styles.container}>
  //       <p className={styles.resume}>Let&apos;s get you a resume!</p>
  //       <p className={styles.text}>
  //         There is no resume currently associated to you. When you create one it will show up here
  //         for you to manage.
  //       </p>
  //       <button className={styles.button} type="text">
  //         <img className={styles.imagePlus} alt="logo" src={plusWhite} />
  //         Add a New Resume
  //       </button>
  //     </div>
  //   </>
  // );
};
