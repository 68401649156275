import React from "react";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

// Controlled TextField Component
const ControlledInput = ({ name, className, placeholder, style, ...props }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...props}
          style={{ ...style }}
          type="text"
          className={className}
          {...field}
          placeholder={placeholder}
          onChange={(e) => field.onChange(e.target.value)}
          error={!!errors[`${name}`]}
          helperText={errors[`${name}`]?.message}
        />
      )}
    />
  );
};

export default ControlledInput;
