import axios from "axios";
import { FlagWidth } from "./downloadDocX/downloadDoc.config";

export const getFlag = async (cv) => {
  try {
    let code = cv?.country?.code;
    if (!code) {
      try {
        let infoData = await axios.get(
          `https://restcountries.com/v3.1/name/${cv?.country?.name}?fullText=true`
        );
        code = infoData.data[0]?.cca2 || "";
      } catch (e) {
        console.error(e);
      }
    }
    const res = await axios.get(`https://flagcdn.com/w${FlagWidth}/${code.toLowerCase()}.png`, {
      responseType: "arraybuffer", // Get the image as a buffer
    });
    return res.data;
  } catch (e) {
    console.error(e);
  }
};
