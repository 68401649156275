import { Paragraph, TableCell, TextRun } from "docx";
import {
  ContextSeparatorBorderColor,
  SkillsTableCellBorders,
  TextHeight12,
} from "./downloadDoc.config";

export const getEmptyLine = () =>
  new Paragraph({
    text: " ",
    spacing: {
      line: 100,
    },
  });

export const getMultilineBulletText = (text, indent = 0) => {
  const data = Array.isArray(text) ? text.join("\n") : text;
  const lines = data.split("\n");
  return lines
    .filter((line) => line !== "")
    .map((line) => {
      return new Paragraph({
        bullet: { level: 0 },
        ...(indent && {
          indent: {
            left: indent,
          },
        }),
        children: [
          new TextRun({
            text: line.trim(),
            size: TextHeight12,
          }),
        ],
      });
    });
};

export const getBulletsList = (cfg, data) => {
  return !!data?.length
    ? data?.map((item) => {
        let text = "";
        cfg.textFieldKeys.forEach(
          (key, idx) =>
            (text =
              text +
              `${item[key] ? `${item[key]}${cfg.textFieldKeys.length - 1 > idx ? ", " : ""} ` : ""}`)
        );
        return new Paragraph({
          bullet: {
            level: 0,
          },
          children: [
            new TextRun({
              text: text,
              size: TextHeight12,
            }),
          ],
        });
      })
    : [];
};

export const getHorizontalLine = () =>
  new Paragraph({
    text: "",
    spacing: {
      before: 50,
      line: 150,
      after: 200,
    },
    border: {
      bottom: {
        color: ContextSeparatorBorderColor,
        space: 1,
        style: "single",
        size: 6,
      },
    },
  });

export const isEmptyObj = (obj) =>
  !Object.keys(obj).some((key) => {
    return !!obj[key] === true;
  });

export const getEmptyCell = (width = 50, borders = SkillsTableCellBorders) =>
  new TableCell({
    width: {
      size: width,
      type: "pct",
    },
    borders: borders,
    children: [],
  });

export const groupProjectsByCompanyAndJobTitle = (projects) => {
  return Object.values(
    projects.reduce((acc, project) => {
      const key = `${project.company}-${project.job_title}`;

      if (!acc[key]) {
        acc[key] = {
          company: project.company,
          job_title: project.job_title,
          projects: [],
        };
      }

      acc[key].projects.push(project);
      return acc;
    }, {})
  );
};

export const getProjectInfo = (projects) => {
  const info = [];
  projects.forEach((project, idx) => {
    !!project?.name &&
      info.push(
        new Paragraph({
          indent: {
            left: 180,
          },
          children: [
            new TextRun({
              text: project?.name,
              bold: true,
              size: TextHeight12,
            }),
            new TextRun({
              text: project?.country?.name ? `, ${project?.country?.name}` : "",
              size: TextHeight12,
            }),
          ],
        })
      );

    // !!project?.industry && //todo they will need it later)
    //   info.push(
    //     ...[
    //       new Paragraph({
    //         indent: {
    //           left: 180,
    //         },
    //         children: [
    //           new TextRun({
    //             text: project?.industry || "",
    //             size: TextHeight12,
    //             bold: true,
    //           }),
    //         ],
    //       }),
    //       getEmptyLine(),
    //     ]
    //   );

    !!project?.description &&
      info.push(
        ...[
          new Paragraph({
            indent: {
              left: 360,
            },
            alignment: "both",
            children: [
              new TextRun({
                text: project?.description || "",
                size: TextHeight12,
              }),
            ],
          }),
          getEmptyLine(),
        ]
      );
    project?.tools_and_technologies &&
      info.push(
        ...[
          new Paragraph({
            indent: {
              left: 360,
            },
            children: [
              new TextRun({
                text: "Tools & Technologies",
                size: TextHeight12,
                bold: true,
              }),
            ],
          }),
          new Paragraph({
            indent: {
              left: 360,
            },
            children: [
              new TextRun({
                text: project?.tools_and_technologies || "",
                size: TextHeight12,
              }),
            ],
          }),
          getEmptyLine(),
        ]
      );
    !!project?.responsibilities &&
      info.push(
        ...[
          new Paragraph({
            indent: {
              left: 360,
            },
            children: [
              new TextRun({
                text: "Responsibilities",
                size: TextHeight12,
                bold: true,
              }),
            ],
          }),
          ...getMultilineBulletText(project?.responsibilities, 1000),
          getEmptyLine(),
        ]
      );
    !!project?.achievements &&
      info.push(
        ...[
          new Paragraph({
            indent: {
              left: 360,
            },
            children: [
              new TextRun({
                text: "Achievements",
                size: TextHeight12,
                bold: true,
              }),
            ],
          }),
          ...getMultilineBulletText(project?.achievements, 1000),
          getEmptyLine(),
        ]
      );
    projects.length - 1 > idx && info.push(getHorizontalLine());
  });
  return info;
};
