import {ROUTES} from "../constants/routes";
import {Auth_service} from "../services/auth.service";

export const setTokens = (token, refreshToken) => {
  localStorage.setItem("token", token);
  localStorage.setItem("refreshToken", refreshToken);
};

export const setQueryParameters = (code, session_state, state, client_info) => {
  localStorage.setItem("code", code);
  localStorage.setItem("session_state", session_state);
  localStorage.setItem("state", state);
  localStorage.setItem("client_info", client_info);
};

export const code = () => localStorage.getItem("code");
export const session_state = () => localStorage.getItem("session_state");
export const state = () => localStorage.getItem("state");
export const client_info = () => localStorage.getItem("client_info");

export const setUser = (user) => {
  localStorage.setItem("user", user);
};
export const User = () => JSON.parse(localStorage.getItem("user"));

export const setLoginURL = (loginURL) => {
  localStorage.setItem("loginURL", JSON.stringify(loginURL));
};
export const getLoginURLLocal = () => JSON.parse(localStorage.getItem("loginURL"));

export const getRefreshToken = () => localStorage.getItem("refreshToken");

export const getToken = () => localStorage.getItem("token");

export const parseJwt = (token) => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
      window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
  );

  setUser(jsonPayload);
};

export const onLogout = async () => {
  await Auth_service.deleteRefreshToken();
  await Auth_service.deleteToken();
  clearRedirect();
};

export const onRefreshToken = () => {
  onLogout();
};

export const clearRedirect = () => {
  localStorage.clear();
  window.location.href = ROUTES.home;
};
