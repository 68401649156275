import React from "react";

const FormNavigationButtons = ({
  onBack,
  onSave,
  isSaveDisabled = false,
  backLabel = "Back",
  saveLabel = "Save & Next",
}) => {
  return (
    <div className="buttonsContainer">
      <button type="button" className={"buttonCancel"} onClick={onBack}>
        {backLabel}
      </button>
      <button
        type="submit"
        className={isSaveDisabled ? "buttonSaveDisabled" : "buttonSave"}
        disabled={isSaveDisabled}
        onClick={onSave}>
        {saveLabel}
      </button>
    </div>
  );
};

export default FormNavigationButtons;
