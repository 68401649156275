import { ALL_CV_LOADING, GET_ALL_CV_DONE, ALL_CV_ERROR } from "../types/allCvTypes";
import {CV_service} from "../../services/cv.service";
import {onRefreshToken} from "../../utils/auth.utils";

export const AllCvAction = {
  request: (loading) => ({
    type: ALL_CV_LOADING,
    loading
  }),

  getReceive: (payload) => ({
    type: GET_ALL_CV_DONE,
    payload
  }),
  failed: (e) => ({
    type: ALL_CV_ERROR,
    error: e
  })
};


export const getAllCV = (pageSize,offsetPage) => async (dispatch) => {
  const { request, getReceive, failed } = AllCvAction;
  dispatch(request(true));
  try {
    let res = await CV_service.getAllCv(pageSize,offsetPage)

    if(res.status === 403) {
      onRefreshToken()
    }
    if (res.data.total !== pageSize) {
      res = await CV_service.getAllCv(res.data.total,0)
    }
    dispatch(getReceive(res.data));
    dispatch(request(false));
  } catch (e) {
    console.log(e);
    if(e.response.status === 403) {
      onRefreshToken()
    }
    dispatch(failed(e));
    dispatch(request(false));
  } finally {
    dispatch(request(false));
  }
};

