import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import ControlledAutocomplete from "../../common/Form/ControlledAutocomplete/ControlledAutocomplete";
import ControlledInput from "../../common/Form/ControlledInput/ControlledInput";
import { monthsAsNumbers, years } from "./constants/constants";
import ContactsLanguageField from "./ContactsLanguageField";
import styles from "./ContactsStep.module.scss";
import { cvContactsSchema } from "./schemas/contactsSchema";
import FormNavigationButtons from "../../common/Form/FormNavigationButtons/FormNavigationButtons";

const ContactsStep = ({
  updateData,
  handleContacts,
  handlePreviousContacts,
  previousContacts,
  countryData,
  jobData,
  languageData,
}) => {
  // const dispatch = useDispatch();
  const pageNum = 2;
  const methods = useForm({
    resolver: zodResolver(cvContactsSchema),
    defaultValues: {
      fullName: "",
      country: null,
      job_title: "",
      experience: "",
      experienceMonth: "",
      languages: [],
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = methods;
  const langFields = watch("languages"); // watching languages fields

  useEffect(() => {
    if (previousContacts) {
      reset({
        fullName: previousContacts?.fullName || "",
        country: previousContacts?.country || null,
        job_title: previousContacts?.job_title || "",
        experience: String(previousContacts?.experience || ""),
        experienceMonth: String(previousContacts?.experienceMonth || ""),
        languages: previousContacts?.languages || ["Elementary"],
      });
    }
  }, [previousContacts, reset]);

  const onSubmit = (data) => {
    handleContacts({
      name: data.fullName,
      country: data.country,
      job_title: data.job_title,
      years_of_experience: Number(data.experience),
      months_of_experience: Number(data.experienceMonth),
      languages: data.languages,
    });

    handlePreviousContacts(data);
    // dispatch(setAvailableCvSteps(pageNum));
    updateData(pageNum);
  };

  const addFields = (e) => {
    e.preventDefault();
    const newLang = {
      name: "",
      code: "",
      level: "",
      id: Math.random().toString(36).substring(2, 9),
    };
    setValue("languages", [...langFields, newLang]);
  };

  const removeFields = (event, index) => {
    event.preventDefault();
    const newLangFields = langFields.filter((_, ind) => ind !== index);
    setValue("languages", newLangFields);
  };

  return (
    <div>
      <div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className={`formContainer ${styles.formContainer}`}>
                <h2 className="title">Contact information</h2>

                <div className="label">For example Sohn s. *</div>
                <ControlledInput
                  name="fullName"
                  className={styles.fullName}
                  placeholder="Full Name *"
                />

                <ControlledAutocomplete
                  name="country"
                  label="Country *"
                  options={countryData}
                  disablePortal
                  getOptionLabel={(option) => option.name}
                />

                <ControlledAutocomplete
                  name="job_title"
                  label="Job Title *"
                  options={jobData}
                  onChange={(jobTitle) => setValue("job_title", jobTitle)}
                  freeSolo
                  disablePortal
                />
                <ControlledAutocomplete
                  name="experience"
                  label="Years of experience *"
                  options={years}
                />

                <ControlledAutocomplete
                  name="experienceMonth"
                  label="Months of experience"
                  options={monthsAsNumbers}
                />

                <div>
                  {langFields.map((_item, index) => (
                    <ContactsLanguageField
                      key={index}
                      index={index}
                      removeFields={removeFields}
                      languageData={languageData}
                    />
                  ))}
                </div>
                <div className="buttonsContainerExperience" style={{ marginTop: "15px" }}>
                  <button className="buttonSave" onClick={addFields}>
                    Add New Language
                  </button>
                </div>
              </div>
            </div>
            <FormNavigationButtons onBack={reset} isSaveDisabled={Object.keys(errors).length} />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ContactsStep;
