export const ROUTES = {
  home: "/home",
  login: "/login",
  steps: "/steps",
  mycvs: "/mycvs",
  allcvs: "/allcvs",
  download: "/download",
  table: "/table",
  employeecv: "/employeeСv",
  authenticated: "/authenticated"
};
