const contacts = (state, action) => {
    switch (action.type) {
        case "editContacts":
        return {
            ...state,
            list: action.payload
        }
        default:
            return state;
    }
}

export default contacts;