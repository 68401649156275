import React from "react";
import { Redirect, Route } from "react-router-dom";
import { ROUTES } from "../constants/routes";

export const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated ? (
            <Component {...props} />
        ) : (
          <Redirect to={ROUTES.login} />
        )
      }
    />
  );
};
