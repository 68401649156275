import React from "react";
import styles from "./Loader.module.scss";
import CircularProgress from "@mui/material/CircularProgress";

export const Loader = () => {
     return <div className={styles.container}>
          <CircularProgress />
          {/*<span className={styles.loader}></span>*/}
     </div>;
};
