import React, { useEffect } from "react";
import { useForm, useFieldArray, Controller, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import { monthData, yearData } from "../../../constants/date";
import FormNavigationButtons from "../../common/Form/FormNavigationButtons/FormNavigationButtons";
import ScrollButtons from "../../common/ScrollButtons/ScrollButtons";
import ControlledInput from "../../common/Form/ControlledInput/ControlledInput";
import ControlledAutocomplete from "../../common/Form/ControlledAutocomplete/ControlledAutocomplete";
import styles from "./ExperienceStep.module.scss";
import { experienceSchema } from "./schemas/experienceSchema";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

const ExperienceStep = ({
  updateData,
  handleExperience,
  handlePreviousExperience,
  previousExperience,
  industryData,
  countryData,
  jobData,
}) => {
  const defaultExperience = {
    company: "",
    name: "",
    under_nda: false,
    industry: "",
    country: null,
    job_title: "",
    start_date_month: "",
    start_date_year: "",
    end_date_month: "",
    end_date_year: "",
    currently_work_here: false,
    description: "",
    responsibilities: "",
    achievements: "",
    tools_and_technologies: "",
  };
  const getTextareaFields = (index) => [
    { name: `experiences.${index}.description`, placeholder: "Project description" },
    { name: `experiences.${index}.responsibilities`, placeholder: "Responsibilities" },
    { name: `experiences.${index}.achievements`, placeholder: "Achievements" },
    { name: `experiences.${index}.tools_and_technologies`, placeholder: "Tools and Technologies" },
  ];
  const methods = useForm({
    resolver: zodResolver(experienceSchema),
    defaultValues: {
      experiences: [{ ...defaultExperience }],
    },
  });

  const { control, handleSubmit, watch, reset, setValue } = methods;
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "experiences",
  });

  const onSubmit = (data) => {
    const processedData = data.experiences.map((exp) => ({
      ...exp,
      name: exp.under_nda ? "Under NDA" : exp.name,
    }));
    handleExperience(processedData);
    handlePreviousExperience(data.experiences);
    updateData(5);
  };

  useEffect(() => {
    if (previousExperience && previousExperience.length) {
      reset({ experiences: previousExperience });
    }
  }, [previousExperience, reset]);

  const handleAddExperience = (event) => {
    event.preventDefault();
    append({ ...defaultExperience });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    move(result.source.index, result.destination.index);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ScrollButtons />
        <div>
          <div className="formContainer">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="experience-list">
                {(provided) => (
                  <Box pt={3} {...provided.droppableProps} ref={provided.innerRef}>
                    {fields.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}>
                            <Accordion defaultExpanded={index === 0} sx={{ margin: "15px 0" }}>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <h2 style={{ fontWeight: 500 }}>Experience {index + 1}</h2>

                                <div
                                  className="cancelContainerExperience"
                                  style={{ width: "220px" }}>
                                  {fields.length > 1 && (
                                    <button
                                      className="buttonCancel"
                                      onClick={(event) => {
                                        event.preventDefault();
                                        remove(index);
                                      }}>
                                      Remove
                                    </button>
                                  )}
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <ControlledInput
                                  name={`experiences.${index}.company`}
                                  className={`form_input ${styles.company}`}
                                  label="Company"
                                  placeholder="Company"
                                />

                                <div>
                                  <ControlledInput
                                    name={`experiences.${index}.name`}
                                    label="Project Name"
                                    placeholder="Project Name"
                                    fullWidth
                                    disabled={watch(`experiences.${index}.under_nda`)}
                                  />

                                  <div className={styles.switcherContainer}>
                                    <Controller
                                      name={`experiences.${index}.under_nda`}
                                      control={control}
                                      render={({ field }) => (
                                        <Switch {...field} checked={field.value} />
                                      )}
                                    />
                                    <label className={styles.switcherLabel}>Under NDA</label>
                                  </div>

                                  <ControlledAutocomplete
                                    name={`experiences.${index}.industry`}
                                    label="Industry"
                                    options={industryData}
                                    disablePortal
                                    getOptionLabel={(option) =>
                                      typeof option === "string" || option instanceof String
                                        ? option
                                        : ""
                                    }
                                  />

                                  <ControlledAutocomplete
                                    name={`experiences.${index}.country`}
                                    label="Country"
                                    options={countryData}
                                    getOptionLabel={(option) => option.name || ""}
                                  />

                                  <ControlledAutocomplete
                                    name={`experiences.${index}.job_title`}
                                    label="Job Title"
                                    options={jobData}
                                    onChange={(jobTitle) =>
                                      setValue(`experiences.${index}.job_title`, jobTitle)
                                    }
                                  />

                                  <div className="dateBlock">
                                    <div className="dateLabel">Start Date</div>
                                    <div className="dateSelect">
                                      <div className="dateInput">
                                        <ControlledAutocomplete
                                          name={`experiences.${index}.start_date_month`}
                                          label="Month"
                                          options={monthData}
                                        />
                                      </div>
                                      <div className="dateInput">
                                        <ControlledAutocomplete
                                          name={`experiences.${index}.start_date_year`}
                                          label="Year"
                                          options={yearData()}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="dateBlock">
                                    <div className="dateLabel">End Date</div>
                                    <div className="dateSelect">
                                      <div className="dateInput">
                                        <ControlledAutocomplete
                                          name={`experiences.${index}.end_date_month`}
                                          label="Month"
                                          options={monthData}
                                        />
                                      </div>
                                      <div className="dateInput">
                                        <ControlledAutocomplete
                                          name={`experiences.${index}.end_date_year`}
                                          label="Year"
                                          options={yearData()}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className={styles.switcherWork}>
                                    <Controller
                                      name={`experiences.${index}.currently_work_here`}
                                      control={control}
                                      render={({ field }) => (
                                        <Switch {...field} checked={field.value} />
                                      )}
                                    />
                                    <label className={styles.switcherLabel}>
                                      Currently work here
                                    </label>
                                  </div>

                                  <div>
                                    <ToggleButtonGroup>
                                      <ToggleButton value="bold" aria-label="bold">
                                        <FormatBoldIcon />
                                      </ToggleButton>
                                      <ToggleButton value="italic" aria-label="italic">
                                        <FormatItalicIcon />
                                      </ToggleButton>
                                      <ToggleButton value="underlined" aria-label="underlined">
                                        <FormatUnderlinedIcon />
                                      </ToggleButton>
                                      <ToggleButton value="left" aria-label="left aligned">
                                        <FormatAlignLeftIcon />
                                      </ToggleButton>
                                    </ToggleButtonGroup>
                                  </div>

                                  {getTextareaFields(index).map((fieldItem) => (
                                    <Controller
                                      key={fieldItem.name}
                                      name={fieldItem.name}
                                      control={control}
                                      render={({ field }) => (
                                        <textarea
                                          {...field}
                                          className={`${styles.styledTextarea} form_input`}
                                          placeholder={fieldItem.placeholder}
                                        />
                                      )}
                                    />
                                  ))}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>

            <div className="buttonsContainerExperience">
              <button className="buttonSave" onClick={handleAddExperience}>
                Add New Experience
              </button>
            </div>
            <br />
          </div>
          <FormNavigationButtons
            onBack={() => updateData(3)}
            isSaveDisabled={!watch("experiences").length}
          />
        </div>
      </form>
    </FormProvider>
  );
};

export default ExperienceStep;
