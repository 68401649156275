export const monthsAsNumbers = Array.from({ length: 12 }, (_, i) => (i + 1).toString());

export const years = Array.from({ length: 30 }, (_, i) => (i + 1).toString());

export const langLevels = [
  "Elementary",
  "Pre-Intermediate",
  "Intermediate",
  "Upper-Intermediate",
  "Advanced",
  "Proficiency",
];
