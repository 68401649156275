// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SummaryStep_notesBlock__gS9N9 {
  width: 224px;
  height: 267px;
  background: #FAFAFA;
  padding: 4px 8px;
  border-radius: 4px;
  margin: 82px 0 0 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: rgba(58, 53, 65, 0.68);
}

li span {
  position: relative;
  left: -10px;
}

.SummaryStep_wrap__CbAvT {
  display: flex;
}

.SummaryStep_errorText__RAFKi {
  font-size: 14px;
  color: red;
  padding-bottom: 24px;
}

.SummaryStep_form_input__P-ZDx {
  width: 100%;
  padding: 16px 12px 16px 17px;
  border: 1px solid rgba(58, 53, 65, 0.23);
  border-radius: 6px;
  color: black;
  font-size: 16px;
  margin-bottom: 10px;
}

.SummaryStep_error_form_input__u7r5D {
  border: 1px solid red;
}`, "",{"version":3,"sources":["webpack://./src/Components/CvSteps/SummaryStep/SummaryStep.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,qBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,6BAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,eAAA;EACA,UAAA;EACA,oBAAA;AACJ;;AAGA;EACI,WAAA;EACA,4BAAA;EACA,wCAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,qBAAA;AAAJ","sourcesContent":[".notesBlock {\n    width: 224px;\n    height: 267px;\n    background: #FAFAFA;\n    padding: 4px 8px;\n    border-radius: 4px;\n    margin: 82px 0 0 22px;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 20px;\n    letter-spacing: 0.4px;\n    color: rgba(58, 53, 65, 0.68);\n}\n\nli span {\n    position: relative;\n    left: -10px;\n}\n\n.wrap{\n    display: flex;\n}\n\n.errorText {\n    font-size: 14px;\n    color: red;\n    padding-bottom: 24px;\n}\n\n\n.form_input {\n    width: 100%;\n    padding: 16px 12px 16px 17px;\n    border: 1px solid rgba(58, 53, 65, 0.23);\n    border-radius: 6px;\n    color: black;\n    font-size: 16px;\n    margin-bottom: 10px;\n}\n\n.error_form_input {\n    border: 1px solid red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notesBlock": `SummaryStep_notesBlock__gS9N9`,
	"wrap": `SummaryStep_wrap__CbAvT`,
	"errorText": `SummaryStep_errorText__RAFKi`,
	"form_input": `SummaryStep_form_input__P-ZDx`,
	"error_form_input": `SummaryStep_error_form_input__u7r5D`
};
export default ___CSS_LOADER_EXPORT___;
