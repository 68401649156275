import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Document, Page } from "react-pdf";
import download from "../../assets/pictures/download.png";
import styles from "./EmployeeСv.module.scss";
import { getPDFData } from "../../utils/downloadPDF.utils";
import { getCvById, setPdfCvHref } from "../../redux/reducers/cvReducer";
import { useDispatch, useSelector } from "react-redux";

const EmployeeCv = () => {
  const [numPages, setNumberPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const downloadEditId = useSelector((state) => state.cvReducer.downloadEditId);
  const pdfCvHref = useSelector((state) => state.cvReducer.pdfCvHref);
  const history = useHistory();
  const userCv = history.location.state.userCv;
  const dispatch = useDispatch();

  const handleGetFile = async (id) => {
    const res = await getPDFData(id);
    dispatch(setPdfCvHref(res));
  };

  useEffect(() => {
    handleGetFile(userCv.id);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumberPages(numPages);
    setPageNumber(1);
  };
  const changePage = (offSet) => setPageNumber((prevPageNumber) => prevPageNumber + offSet);

  const changePageBack = () => changePage(-1);

  const changePageNext = () => changePage(+1);

  const back = () =>
    history.push({
      pathname: "/allcvs",
    });

  let downloadFile = () => {
    const aElement = document.createElement("a");
    aElement.setAttribute("download", `${userCv.name}.pdf`);
    aElement.href = pdfCvHref;
    aElement.setAttribute("target", "_blank");
    aElement.click();
    URL.revokeObjectURL(pdfCvHref);
  };

  const getCvByID = async (id) => {
    const result = await dispatch(getCvById(id));

    if (getCvById.fulfilled.match(result)) {
      const edit = result.payload;
      history.push({
        pathname: "/steps",
        state: { edit },
      });
    }
  };

  return (
    <div className={styles.containerCv}>
      <div className={styles.buttonGrup}>
        <button className={styles.previewBtn} type="text" onClick={() => getCvByID(downloadEditId)}>
          EDIT
        </button>
        <button
          className={styles.previewBtn}
          style={{ marginLeft: "15px" }}
          type="text"
          onClick={back}>
          BACK
        </button>
        <button className={styles.downloadBtn} type="text" onClick={downloadFile}>
          <img
            className={styles.imgDownload}
            alt="logo"
            width="11.67px"
            height="14.17px"
            src={download}
          />
          DOWNLOAD
        </button>
      </div>
      <div className={styles.containerPdf}>
        <Document file={pdfCvHref} onLoadSuccess={onDocumentLoadSuccess}>
          <Page height="700" pageNumber={pageNumber} />
        </Document>
        <div className={styles.pagination}>
          <p className={styles.title}>
            {" "}
            Page {pageNumber} of {numPages}
          </p>
          {pageNumber > 1 && (
            <button className={styles.btnPdf} onClick={changePageBack}>
              Previous Page
            </button>
          )}
          {pageNumber < numPages && (
            <button className={styles.btnPdf} onClick={changePageNext}>
              Next Page
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeCv;
