import { ALL_CV_LOADING, GET_ALL_CV_DONE, ALL_CV_ERROR } from "../types/allCvTypes";


const initialState = {
    list: [],
    isLoading: false,
    error: null
};

const allCvReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALL_CV_LOADING:
            return {
                ...state,
                isLoading: action.loading
            };


        case GET_ALL_CV_DONE:
            return {
                ...state,
                list: action.payload // eslint-disable-line
            };
        case ALL_CV_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
};

export default allCvReducer;
