import React, { useState, Suspense } from "react";
import preview from "../../assets/pictures/preview.png";
import download from "../../assets/pictures/download.png";
import styles from "./Download.module.scss";
import EditeIcon from "../../assets/pictures/Edit.png";
import { useDispatch, useSelector } from "react-redux";
import { getCvById } from "../../redux/reducers/cvReducer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Download = ({ pdfCv, handleDownload }) => {
  const [_, setViewPdf] = useState(true);
  const downloadEditId = useSelector((state) => state.cvReducer.downloadEditId);
  const dispatch = useDispatch();
  const history = useHistory();

  const onToggle = () => setViewPdf((preViewPdf) => !preViewPdf);
  const getCvByID = async (id) => {
    const result = await dispatch(getCvById(id));

    if (getCvById.fulfilled.match(result)) {
      const edit = result.payload;
      history.push({
        pathname: "/steps",
        state: { edit },
      });
    }
  };
  return (
    <div>
      <div>
        <div>
          <Suspense fallback={<div>Loading...</div>}>
            <div className={(styles.download, styles.margin)}>
              <div className={styles.buttonGrup}>
                <button
                  onClick={() => getCvByID(downloadEditId)}
                  className={styles.previewBtn}
                  type="text">
                  <img
                    className={styles.imgPreview}
                    alt="logo"
                    width="18.33px"
                    height="12.5px"
                    src={EditeIcon}
                  />
                  EDIT
                </button>
                <button onClick={onToggle} className={styles.previewBtn} type="text">
                  <img
                    className={styles.imgPreview}
                    alt="logo"
                    width="18.33px"
                    height="12.5px"
                    src={preview}
                  />
                  PREVIEW
                </button>
                <button className={styles.downloadBtn} type="text" onClick={() => handleDownload()}>
                  <img
                    className={styles.imgDownload}
                    alt="logo"
                    width="11.67px"
                    height="14.17px"
                    src={download}
                  />
                  DOWNLOAD
                </button>
              </div>
              <div>
                <iframe src={pdfCv} className={styles.iFramePdf}></iframe>
              </div>
            </div>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Download;
