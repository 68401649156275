import { z } from "zod";

// if validation is needed - uncomment
const educationSchema = z.object({
  school_name: z.string().optional().nullable(), //.min(1, "School name is required"),
  degree: z.string().optional().nullable(), //.min(1, "Degree is required"),
  field_of_studies: z.string().optional().nullable(), //.min(1, "Field of studies is required"),
  graduate_date_month: z.string().optional().nullable(), //.min(1, "Graduate month is required"),
  graduate_date_year: z.string().optional().nullable(), //.min(1, "Graduate year is required"),
}).optional().nullable();

export const educationFormSchema = z.object({
  educations: z.array(educationSchema).optional(),
}).optional();
