import {Endpoints} from "../api/endpoints";
import {axiosInstance} from "../api/api";

export const CV_service = {
    getAllCv(pageSize, offsetPage) {
        return axiosInstance.get(Endpoints.GET_ALL_CV, {
            params: {
                limit: pageSize,
                offset: offsetPage,
            },
        });
    },

    getMyCvs(pageSize, offsetPage, sub) {
        return axiosInstance.get(Endpoints.GET_ALL_CV, {
            params: {
                search_field: "creator_id",
                search_text: sub,
                limit: pageSize,
                offset: offsetPage
            },
        });
    },

    getCVByID(id) {
        return axiosInstance.get(`${Endpoints.GET_ALL_CV}${id}`);
    },

    putCVByID(id, data) {
        return axiosInstance.put(`${Endpoints.GET_ALL_CV}${id}`, data);
    },

    postCV(data) {
        return axiosInstance.post(Endpoints.GET_ALL_CV, data);
    },

    getCVOptions() {
        return Promise.all([
            axiosInstance.get(Endpoints.GET_SKILLS),
            axiosInstance.get(Endpoints.GET_INDUSTRY),
            axiosInstance.get(Endpoints.GET_COUNTRIES),
            axiosInstance.get(Endpoints.GET_JOB_TITLE),
            axiosInstance.get(Endpoints.GET_LANGUAGE),
            axiosInstance.get(Endpoints.GET_DEGREES),
        ]);
    },
    deleteCV(id){
        return axiosInstance.delete(`${Endpoints.GET_ALL_CV}${id}`);

    }
};