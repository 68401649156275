import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { langLevels } from "./constants/constants";
import { Controller, useFormContext } from "react-hook-form";

const ContactsLanguageField = ({ index, removeFields, languageData }) => {
  const { control, watch } = useFormContext();

  const languageName = watch(`languages.${index}.name`);
  const languageLevel = watch(`languages.${index}.level`);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <Controller
          name={`languages.${index}.name`}
          control={control}
          render={({ field }) => (
            <FormControl sx={{ mt: 1, width: "50%" }}>
              <InputLabel id="contacts-lang-name" sx={{ background: "white", p: "0 5px" }}>
                Language
              </InputLabel>
              <Select
                labelId="label"
                {...field}
                value={languageName}
                onChange={(e) => field.onChange(e.target.value)}
                error={!!field.error}>
                {languageData.map((lang) => (
                  <MenuItem key={lang.id} value={lang.name}>
                    {lang.name}
                  </MenuItem>
                ))}
              </Select>
              <span>{field.error?.message}</span>
            </FormControl>
          )}
        />

        <Controller
          name={`languages.${index}.level`}
          control={control}
          render={({ field }) => (
            <FormControl sx={{ mt: 1, ml: 1, width: "50%" }}>
              <InputLabel id="contacts-lang-level" sx={{ background: "white", p: "0 5px" }}>
                Level
              </InputLabel>
              <Select
                {...field}
                value={languageLevel}
                onChange={(e) => field.onChange(e.target.value)}
                error={!!field.error}>
                {langLevels.map((level) => (
                  <MenuItem key={level} value={level}>
                    {level}
                  </MenuItem>
                ))}
              </Select>
              <span>{field.error?.message}</span>
            </FormControl>
          )}
        />
      </div>
      <div className="cancelContainerExperience">
        {watch("languages")?.length > 1 && (
          <button
            className="buttonCancel"
            onClick={(event) => removeFields(event, index)}
            style={{ marginTop: "10px" }}>
            Remove
          </button>
        )}
      </div>
    </>
  );
};

export default ContactsLanguageField;
