import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect } from "react";
import { monthData, yearData } from "../../../constants/date";
import ScrollButtons from "../../common/ScrollButtons/ScrollButtons";
import { certificationFormSchema } from "./schemas/certificationSchema";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import ControlledAutocomplete from "../../common/Form/ControlledAutocomplete/ControlledAutocomplete";
import ControlledInput from "../../common/Form/ControlledInput/ControlledInput";
import FormNavigationButtons from "../../common/Form/FormNavigationButtons/FormNavigationButtons";

const CertificationStep = ({
  updateData,
  handleCertification,
  handlePreviousCertification,
  previousCertification,
}) => {
  const pageNum = 4;
  const defaultCertification = {
    certificate_name: "",
    description: "",
    date_of_obtaining_month: "",
    date_of_obtaining_year: "",
  };
  const methods = useForm({
    defaultValues: {
      certifications: previousCertification || [{ ...defaultCertification }],
    },
    resolver: zodResolver(certificationFormSchema),
  });
  const { control, handleSubmit, reset } = methods;
  const { fields, append, remove } = useFieldArray({ control, name: "certifications" });

  useEffect(() => {
    if (previousCertification && previousCertification.length) {
      reset({ certifications: previousCertification });
    } else {
      reset({ certifications: [{ ...defaultCertification }] });
    }
  }, [previousCertification, reset]);

  const submit = (data) => {
    handleCertification(data.certifications);
    handlePreviousCertification(data.certifications);
    updateData(pageNum);
  };

  const onToggleBack = () => updateData(pageNum - 2);

  const handleAddNewCertification = () => {
    append({ ...defaultCertification });
  };

  const handleRemoveOneCertification = (event, index) => {
    event.preventDefault();
    remove(index);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        <div>
          <ScrollButtons />
          <div>
            <div className="formContainer">
              {fields.map((field, index) => {
                return (
                  <React.Fragment key={field.id}>
                    <h2 className="title">Certification {index + 1}</h2>

                    <div className="label" key={index}>
                      For example Professional Scrum Master
                    </div>
                    <ControlledInput
                      name={`certifications.${index}.certificate_name`}
                      className="form_input"
                      placeholder="Certificate Name"
                      style={{ marginBottom: "24px" }}
                    />

                    <ControlledInput
                      name={`certifications.${index}.description`}
                      className="form_input"
                      placeholder="Description"
                      style={{ marginBottom: "24px" }}
                    />

                    <div className="dateBlock">
                      <div className="dateLabel">Date of obtaining</div>
                      <div className="dateSelect">
                        <div className="dateInput">
                          <ControlledAutocomplete
                            name={`certifications.${index}.date_of_obtaining_month`}
                            label="Month"
                            options={monthData}
                          />
                        </div>
                        <div className="dateInput">
                          <ControlledAutocomplete
                            name={`certifications.${index}.date_of_obtaining_year`}
                            label="Year"
                            options={yearData()}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="cancelContainerExperience">
                      {fields.length > 1 && (
                        <button
                          className="buttonCancel"
                          onClick={(event) => handleRemoveOneCertification(event, index)}>
                          Remove
                        </button>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}

              <div className="buttonsContainerExperience">
                <button
                  type="button"
                  className="buttonSave"
                  onClick={handleAddNewCertification}
                  style={{ marginBottom: "24px" }}>
                  Add New Certificate
                </button>
              </div>
            </div>
          </div>
          <FormNavigationButtons onBack={onToggleBack}  />
        </div>
      </form>
    </FormProvider>
  );
};

export default CertificationStep;
