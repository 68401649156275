import React, { useEffect } from "react";
import { Box, Step, Stepper, StepButton } from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { clearCvSteps } from "../../redux/reducers/cvReducer";

const steps = [
  {
    label: "Contacts",
  },
  {
    label: "Education",
  },
  {
    label: "Certification",
  },
  {
    label: "Experience",
  },
  {
    label: "Skills",
  },
  {
    label: "Summary",
  },
  {
    label: "Preview",
  },
];

const LeftStepper = ({ step, updateData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isNew = history.location.state && history.location.state.addNewCV;
  const availableCvSteps = useSelector((state) => state.cvReducer.availableCvSteps);

  const isStepAvailable = (stepNumber) => {
    const step = availableCvSteps.find((s) => s.step === stepNumber);
    return isNew === undefined ? true : step ? step.completed : false;
  };

  const handleStep = (stp) => () => {
    if (isStepAvailable(stp)) {
      updateData(stp);
    }
  };

  useEffect(() => {
    isNew && dispatch(SetAvailableCvStepsAction.clearCvSteps());
    return () => {
      dispatch(clearCvSteps());
    };
  }, [isNew]);

  return (
    <div>
      <Box>
        <Stepper activeStep={step} nonLinear orientation="vertical">
          {steps.map((st, index) => (
            <Step key={st.label}>
              <StepButton disabled={!isStepAvailable(index + 1)} onClick={handleStep(index + 1)}>
                {st.label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
};

export default LeftStepper;
