import { zodResolver } from "@hookform/resolvers/zod";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as z from "zod";
import styles from "./SummaryStep.module.scss";
import FormNavigationButtons from "../../common/Form/FormNavigationButtons/FormNavigationButtons";

// Validation schema
const schema = z.object({
  achievements: z.string().min(3, "Achievements must contain at least 3 characters"),
});

const SummaryStep = ({ updateData, achievementsHandler, data }) => {
  const [pageNum] = useState({ name: 7 });

  // Setting up react-hook-form with FormProvider and zod validation
  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      achievements: data || "",
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const onSubmit = (formData) => {
    updateData(pageNum.name);
    achievementsHandler(formData.achievements);
  };

  const onToggleBack = () => {
    updateData(pageNum.name - 2);
  };

  return (
    <FormProvider {...methods}>
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.wrap}>
          <span>
            <div className="formContainer">
              <h2 className="title">Summary*</h2>
              <div>
                <div>
                  <ToggleButtonGroup>
                    <ToggleButton value="bold" aria-label="bold">
                      <FormatBoldIcon />
                    </ToggleButton>
                    <ToggleButton value="italic" aria-label="italic">
                      <FormatItalicIcon />
                    </ToggleButton>
                    <ToggleButton value="underlined" aria-label="underlined">
                      <FormatUnderlinedIcon />
                    </ToggleButton>
                    <ToggleButton value="left" aria-label="left aligned">
                      <FormatAlignLeftIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                <Controller
                  name="achievements"
                  control={control}
                  render={({ field }) => (
                    <textarea
                      {...field}
                      className={`${errors.achievements && styles.error_form_input} ${styles.form_input}`}
                      rows="10"
                      placeholder="Enter your achievements here"
                    />
                  )}
                />
                {errors.achievements && (
                  <p className={styles.errorText}>{errors.achievements.message}</p>
                )}
              </div>
            </div>
            <FormNavigationButtons onBack={onToggleBack} isSaveDisabled={!!errors.achievements} />
          </span>
          <span>
            <div className={styles.notesBlock}>
              E.g.: (Please specify only those achievements which a) show proficiency; b) are
              extraordinary; c) save client’s money)
              <br />
              • Developed a mobile application that connects doctors with people whose smart watch
              recorded suspicious symptoms
              <br />
              • Won Ukrainian startup hackathon
              <br />• Redesigned 80%-complete mobile application and reduced the cost of maintenance
              to 60%.
            </div>
          </span>
        </div>
      </form>
    </FormProvider>
  );
};

export default SummaryStep;
