// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PreviewStep_info__dbzWK {
  font-size: 16px;
  color: rgba(58, 53, 65, 0.8705882353);
  line-height: 24px;
}

.PreviewStep_buttonsContainer__vZuQy {
  display: flex;
  justify-content: end;
  gap: 8px;
  margin-top: 16px;
  padding-bottom: 19px;
}
.PreviewStep_buttonsContainer__vZuQy .PreviewStep_button__jXIN\\+ {
  padding: 5px 13px;
  border: 1px solid rgba(58, 53, 65, 0.6784313725);
  border-radius: 5px;
  background-color: inherit;
  font-size: 14px;
  color: rgba(58, 53, 65, 0.8705882353);
  font-weight: 500;
  cursor: pointer;
}
.PreviewStep_buttonsContainer__vZuQy .PreviewStep_button__jXIN\\+ .PreviewStep_editContainer__9JsMU, .PreviewStep_buttonsContainer__vZuQy .PreviewStep_button__jXIN\\+ .PreviewStep_deleteContainer__Y8MfY {
  display: flex;
  gap: 7px;
}`, "",{"version":3,"sources":["webpack://./src/Components/CvSteps/PreviewStep/PreviewStep.module.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,qCAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,aAAA;EACA,oBAAA;EACA,QAAA;EACA,gBAAA;EACA,oBAAA;AAAJ;AAEI;EACI,iBAAA;EACA,gDAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,qCAAA;EACA,gBAAA;EACA,eAAA;AAAR;AAEQ;EACI,aAAA;EACA,QAAA;AAAZ","sourcesContent":["\n.info {\n    font-size: 16px;\n    color: #3A3541DE;\n    line-height: 24px;\n}\n\n.buttonsContainer {\n    display: flex;\n    justify-content: end;\n    gap: 8px;\n    margin-top: 16px;\n    padding-bottom: 19px;\n\n    .button {\n        padding: 5px 13px;\n        border: 1px solid #3A3541AD;\n        border-radius: 5px;\n        background-color: inherit;\n        font-size: 14px;\n        color: #3A3541DE;\n        font-weight: 500;\n        cursor: pointer;\n\n        .editContainer, .deleteContainer {\n            display: flex;\n            gap: 7px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"info": `PreviewStep_info__dbzWK`,
	"buttonsContainer": `PreviewStep_buttonsContainer__vZuQy`,
	"button": `PreviewStep_button__jXIN+`,
	"editContainer": `PreviewStep_editContainer__9JsMU`,
	"deleteContainer": `PreviewStep_deleteContainer__Y8MfY`
};
export default ___CSS_LOADER_EXPORT___;
