import * as z from "zod";

// zod schema for validation
export const skillsSchema = z.object({
  general_info: z.string().optional(),
  skills: z.array(z.string().optional()),
  extra_skills: z.string().optional(),
  bug_tracking_and_pm: z.string().optional(),
  devops_skills: z.string().optional(),
  design_patterns: z.string().optional(),
  other_skills: z.string().optional(),
});
