export const Endpoints = {
    GET_ALL_CV: "api/v2/cvs/",

    //auth
    GET_LOGIN_URL: "microsoft-auth/get-login-url",
    REFRESH_TOKEN_USER: "microsoft-auth/refresh/",
    DELETE_REFRESH_TOKEN: "microsoft-auth/revoke-refresh",
    DELETE_TOKEN: "microsoft-auth/revoke-access",
    GET_TOKEN_WITH_REDIRECT: "microsoft-auth/redirect-url",

    //multiformApi
    GET_SKILLS: "api/v2/skills/?limit=110",
    GET_INDUSTRY: "api/v2/industries/?limit=150",
    GET_COUNTRIES: "api/v2/countries/?limit=250",
    GET_JOB_TITLE: "api/v2/job-titles/",
    GET_LANGUAGE: "api/v2/languages/?limit=182",
    GET_DEGREES: "api/v2/degrees/",
}