// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tab_container__oiT\\+7 {
  padding: 20px 0 0 10.5%;
}

.Tab_tabs__sNIHt {
  display: flex;
}
.Tab_tabs__sNIHt .Tab_tab__RvJlW {
  justify-content: center;
  align-items: center;
  width: 6%;
  min-width: 62px;
  padding: 1em 0;
  margin: 0 10px;
  border: none;
  background: #EBF1FE;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: rgba(58, 53, 65, 0.68);
  cursor: pointer;
}
.Tab_tabs__sNIHt .Tab_tab__RvJlW:hover {
  color: rgba(145, 85, 253, 0.76);
}
.Tab_tabs__sNIHt .Tab_tab__RvJlW.Tab_active__0j\\+jF {
  border-bottom: 2px solid #9155FD;
  margin-bottom: -2px;
  color: #9155FD;
}

.Tab_panel__wIg2g {
  display: none;
  padding: 10px;
}

.Tab_panel__wIg2g.Tab_active__0j\\+jF {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/Components/Tab/Tab.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;;AACA;EACI,aAAA;AAEJ;AADE;EACE,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,eAAA;EACA,cAAA;EACA,cAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,6BAAA;EACA,eAAA;AAGJ;AAFI;EACE,+BAAA;AAIN;AAAE;EACE,gCAAA;EACA,mBAAA;EACA,cAAA;AAEJ;;AAEE;EACE,aAAA;EACA,aAAA;AACJ;;AACE;EACE,cAAA;AAEJ","sourcesContent":[".container{\n  padding: 20px 0 0 10.5%\n}\n.tabs {\n    display: flex;\n  .tab {\n    justify-content: center;\n    align-items: center;\n    width: 6%;\n    min-width: 62px;\n    padding: 1em 0;\n    margin: 0 10px;\n    border: none;\n    background: #EBF1FE;\n    font-style: normal;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 24px;\n    color: rgba(58, 53, 65, 0.68);\n    cursor: pointer;\n    &:hover{\n      color: rgba(145, 85, 253, 0.76);\n    }\n  }\n\n  .tab.active {\n    border-bottom: 2px solid #9155FD;\n    margin-bottom: -2px;\n    color: #9155FD;\n  }\n  }\n\n  .panel {\n    display: none;\n    padding: 10px;\n  }\n  .panel.active {\n    display: block;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Tab_container__oiT+7`,
	"tabs": `Tab_tabs__sNIHt`,
	"tab": `Tab_tab__RvJlW`,
	"active": `Tab_active__0j+jF`,
	"panel": `Tab_panel__wIg2g`
};
export default ___CSS_LOADER_EXPORT___;
