// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SkillsStep_explanation__3XWZN {
  color: rgba(0, 0, 0, 0.38);
  margin-bottom: 15px;
}

.SkillsStep_divider__sxDWl {
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.SkillsStep_divider__sxDWl::before {
  content: " ";
  flex: 1 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
  margin-right: 15px;
}
.SkillsStep_divider__sxDWl::after {
  content: " ";
  flex: 1 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.38);
  margin-left: 15px;
}

.SkillsStep_skillTextarea__b3def {
  max-width: 100%;
  min-width: 100%;
  height: 52px;
  min-height: 52px;
}

.SkillsStep_errorMessage__ceBvw {
  color: red;
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Components/CvSteps/SkillsStep/SkillsStep.module.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,mBAAA;AACF;;AACA;EACE,kBAAA;EACA,0BAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AAEF;AADE;EACE,YAAA;EACA,SAAA;EACA,4CAAA;EACA,kBAAA;AAGJ;AADE;EACE,YAAA;EACA,SAAA;EACA,4CAAA;EACA,iBAAA;AAGJ;;AACA;EACE,eAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;AAEF;;AACA;EACE,UAAA;EACA,mBAAA;AAEF","sourcesContent":[".explanation {\n  color: rgba(0, 0, 0, 0.38);\n  margin-bottom: 15px;\n}\n.divider {\n  text-align: center;\n  color: rgba(0, 0, 0, 0.38);\n  display: flex;\n  align-items: center;\n  margin-bottom: 25px;\n  &::before {\n    content: \" \";\n    flex: 1 1;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.38);\n    margin-right: 15px;\n  }\n  &::after {\n    content: \" \";\n    flex: 1 1;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.38);\n    margin-left: 15px;\n  }\n}\n\n.skillTextarea {\n  max-width: 100%;\n  min-width: 100%;\n  height: 52px;\n  min-height: 52px;\n}\n\n.errorMessage {\n  color: red;\n  margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"explanation": `SkillsStep_explanation__3XWZN`,
	"divider": `SkillsStep_divider__sxDWl`,
	"skillTextarea": `SkillsStep_skillTextarea__b3def`,
	"errorMessage": `SkillsStep_errorMessage__ceBvw`
};
export default ___CSS_LOADER_EXPORT___;
