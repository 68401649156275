import * as z from "zod";

// zod schema for validation
export const cvContactsSchema = z
  .object({
    fullName: z
      .string()
      .regex(/^\S+(\s+\S+)+\s*$/, "Name must contain first name and last name")
      .nonempty("Name is required"),
    country: z.object({ name: z.string().nonempty("Country is required") }),
    job_title: z.string().nonempty("Job is required"),
    experience: z.string().nonempty("Years of experience are required"),
    experienceMonth: z.string().nullable().optional(),
    languages: z.array(
      z
        .object({
          id: z.string().optional().nullable(),
          code: z.string().optional().nullable(),
          level: z.string().optional().nullable(),
          name: z.string().optional().nullable(),
        })
        .nullable()
    ),
  })
  .optional();
