import {
  setTokens,
  setLoginURL,
  getLoginURLLocal, parseJwt, clearRedirect,
} from "../utils/auth.utils";

import { ROUTES } from "../constants/routes";
import { axiosInstance } from "../api/api";
import { Endpoints } from "../api/endpoints";

export const Auth_service = {
  getLoginURL() {
    axiosInstance
      .get(Endpoints.GET_LOGIN_URL, {
        params: { redirect_url: `${window.location.origin}/authenticated` },
      })
      .then((data) => {
        if (data.status === 200) {
          const response = data.data;
          const uri = response.auth_uri;

          setLoginURL(response);
          window.location.href = uri;
        }
      })
      .catch((err) => console.error(err));
  },

  getLoginToken(code, session_state, state, client_info) {
    const loginURL = getLoginURLLocal();

    if (loginURL) {
      const data = {
        auth_msal_params: {
          auth_uri: loginURL.auth_uri,
          claims_challenge: loginURL.claims_challenge,
          code_verifier: loginURL.code_verifier,
          nonce: loginURL.nonce,
          redirect_uri: loginURL.redirect_uri,
          scope: loginURL.scope,
          state: loginURL.state,
        },
        auth_server_params: {
          code: code,
          session_state: session_state,
          state: state,
          client_info: client_info,
        },
      };

      axiosInstance
        .post(Endpoints.GET_TOKEN_WITH_REDIRECT, data)
        .then((response) => {
          const token = response.data.access_token;
          setTokens(response.data.access_token, response.data.refresh_token);
          parseJwt(token);
          window.location.href = ROUTES.home;
        })
        .catch((error) => {
          console.log(error);
          clearRedirect();
        });
    }
  },

  deleteRefreshToken() {
    axiosInstance
      .delete(Endpoints.DELETE_REFRESH_TOKEN)
      .then((response) => {
        console.log("deleteRefreshToken data: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  deleteToken() {
    axiosInstance
      .delete(Endpoints.DELETE_TOKEN)
      .then((response) => {
        console.log("deleteToken data: ", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

