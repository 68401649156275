import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CV_service } from "../../services/cv.service";
import { getPDFData } from "../../utils/downloadPDF.utils";
import { onRefreshToken } from "../../utils/auth.utils";
import { getCVCopy } from "../../utils/duplicateCvUtil";

export const editCV = createAsyncThunk(
  "cv/editCV",
  async ({ editID, data }, { rejectWithValue }) => {
    try {
      const res = await CV_service.putCVByID(editID, data);
      if (res.status === 403) {
        onRefreshToken();
      }
      return res.data;
    } catch (error) {
      dispatch(setErrorModal(true));
      dispatch(setErrorText(error.message));
      return rejectWithValue(error.message);
    }
  }
);

export const getPDFFile = createAsyncThunk("cv/getPDFFile", async (url, { dispatch }) => {
  const res = await getPDFData(url.data.id);
  dispatch(setPdfCvHref(res));
});

export const getCvById = createAsyncThunk(
  "cv/getCvById",
  async (downloadEditId, { rejectWithValue }) => {
    try {
      const res = await CV_service.getCVByID(downloadEditId);
      const edit = res.data;
      return edit;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const saveCv = createAsyncThunk("cv/saveCv", async (data, { dispatch, rejectWithValue }) => {
  try {
    const res = await CV_service.postCV(data);
    dispatch(setDownloadCvEditId(res.data.id));
    dispatch(getPDFFile(res));

    return res.data;
  } catch (error) {
    dispatch(setErrorModal(true));
    dispatch(setErrorText(error.message));
    return rejectWithValue(error.message);
  }
});

export const getCvOptions = createAsyncThunk(
  "cv/getCvOptions",
  async (onRefreshToken, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoading(true));
    try {
      const resultData = await CV_service.getCVOptions();
      dispatch(setIsLoading(false));
      return resultData;
    } catch (e) {
      if (e.response && e.response.status === 403) {
        onRefreshToken();
      }
      dispatch(setErrorModal(true));
      dispatch(setErrorText(e.message));
      dispatch(setIsLoading(false));
      console.error("Error fetching CV options:", e);
      return rejectWithValue(e.message);
    }
  }
);

export const postCVCopy = createAsyncThunk("cv/postCVCopy", async (data, { rejectWithValue }) => {
  try {
    await CV_service.postCV(data);
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const duplicateCv = createAsyncThunk(
  "cv/duplicateCv",
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const cv = await dispatch(getCvById(id));
      const generatedCVCopy = getCVCopy(cv.payload);
      dispatch(postCVCopy(generatedCVCopy));
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const cvSlice = createSlice({
  name: "cv",
  initialState: {
    availableCvSteps: [{ step: 1, completed: false }],
    downloadEditId: "",
    pdfCvHref: "",
    errorModal: false,
    errorText: "",
    isLoading: false,
  },
  reducers: {
    clearCvSteps: (state) => {
      state.availableCvSteps = [{ step: 1, completed: false }];
    },
    completeCvStep: (state, action) => {
      const { step, completed } = action.payload;
      const existingStep = state.availableCvSteps.find((s) => s.step === step);
      if (existingStep) {
        existingStep.completed = completed;
      } else {
        state.availableCvSteps.push({ step, completed });
      }
    },
    setDownloadCvEditId: (state, action) => {
      state.downloadEditId = action.payload;
    },
    setPdfCvHref: (state, action) => {
      state.pdfCvHref = action.payload;
    },
    setErrorModal: (state, action) => {
      state.errorModal = action.payload;
    },
    setErrorText: (state, action) => {
      state.errorText = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveCv.fulfilled, (state, action) => {
        state.downloadEditId = action.payload.id;
      })
      .addCase(editCV.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const {
  clearCvSteps,
  completeCvStep,
  setDownloadCvEditId,
  setPdfCvHref,
  setErrorModal,
  setErrorText,
  setIsLoading,
} = cvSlice.actions;

export default cvSlice.reducer;
