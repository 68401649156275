import { IconButton, styled } from "@mui/material";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

export const IconButtonStyled = styled(IconButton)`
  height: 80px;
  width: 80px;
  position: fixed;
  right: 50px;
  top: 50px;
`;

export const IconButtonBottomStyled = styled(IconButton)`
  height: 80px;
  width: 80px;
  position: fixed;
  right: 50px;
  bottom: 50px;
`;

export const KeyboardArrowUpOutlinedIconStyled = styled(KeyboardArrowUpOutlinedIcon)`
  height: 60px;
  width: 45px;
  color: #9155fd;
`;

export const KeyboardArrowDownOutlinedIconStyled = styled(KeyboardArrowDownOutlinedIcon)`
  height: 60px;
  width: 45px;
  color: #9155fd;
`;
