import React, { useState } from "react";
import styles from "./Tab.module.scss";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

const Tabs = ({ active }) => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const isAdmin = (user && user.role !==0) || false;
  const [activeIndex, setActiveIndex] = useState(active);
  const ClickMyCvs = (index) => {
    setActiveIndex(index);
    history.push(ROUTES.mycvs);
  };
  const ClickAllCvs = (index) => {
    setActiveIndex(index);
    history.push(ROUTES.allcvs);
  };
  const checkActive = (index, className) => (activeIndex === index ? className : "");
  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <button
          className={`${styles.tab} ${checkActive(1, `${styles.active}`)}`}
          onClick={() => ClickMyCvs(1)}>
          MY CVS
        </button>
        {isAdmin && (
          <button
            className={`${styles.tab} ${checkActive(2, `${styles.active}`)}`}
            onClick={() => ClickAllCvs(2)}>
            ALL CVS
          </button>
        )}
      </div>
      <div>
        <div className={`${styles.panel} ${checkActive(1, `${styles.active}`)}`}></div>
        <div className={`${styles.panel} ${checkActive(2, `${styles.active}`)}`}></div>
      </div>
    </div>
  );
};

export default Tabs;
