import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store  from "./redux/store";
import { App } from "./App.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <div className="app">
        <Router>
          <App />
        </Router>
      </div>
    </Provider>
  /* </React.StrictMode> */
);

reportWebVitals();
