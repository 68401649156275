import { Autocomplete, TextField, Chip } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import useStyles from "../../../../override";

const ControlledAutocomplete = ({
  name,
  label,
  options = [],
  getOptionLabel,
  freeSolo = false,
  disablePortal = false,
  multiple = false,
  renderTags,
  style,
  onChange,
  ...props
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const classes = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          {...props}
          multiple={multiple}
          options={options}
          style={{ ...style }}
          className={classes.selectPicker}
          getOptionLabel={getOptionLabel}
          onChange={(_, value) => {
            field.onChange(value);
          }}
          onInputChange={(_, value) => {
            onChange && onChange(value);
          }}
          freeSolo={freeSolo}
          disablePortal={disablePortal}
          renderTags={
            renderTags ??
            ((value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={index} label={option} {...getTagProps({ index })} />
              )))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!errors[name]}
              helperText={errors[name]?.message}
            />
          )}
        />
      )}
    />
  );
};

export default ControlledAutocomplete;
