import React, { useState } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import styles from "./PreviewStep.module.scss";
import stylesSummary from "../SummaryStep/SummaryStep.module.scss";
import editIcon from "../../../assets/svg/edit.svg";
import deleteIcon from "../../../assets/svg/delete.svg";
import FormNavigationButtons from "../../common/Form/FormNavigationButtons/FormNavigationButtons";
import { editCV, saveCv } from "../../../redux/reducers/cvReducer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// validation schema
const schema = z.object({
  achievements: z.string().min(3, "Achievements must contain at least 3 characters"),
});

const PreviewStep = ({ updateData, data, edit, allData }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [pageNum] = useState({ name: 8 });
  const dispatch = useDispatch();
  const history = useHistory();

  const downloadEditId = useSelector((state) => state.cvReducer.downloadEditId);
  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      achievements: data || "",
    },
    mode: "onChange",
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = methods;

  const onSubmit = async (formData) => {
    if (edit) {
      const result = await dispatch(
        editCV({ editID: downloadEditId, data: { ...allData, summary: formData.achievements } })
      );
      if (editCV.fulfilled.match(result)) {
        let userCv = history.location.state.edit;
        history.push({
          pathname: "/employeeСv",
          state: { userCv },
        });
      }
    } else {
      dispatch(saveCv(allData));
    }
    updateData(pageNum.name);
  };

  const onToggleBack = () => {
    updateData(pageNum.name - 2);
  };

  const handleEditToggle = () => {
    setIsEdit((prevState) => !prevState);
  };

  const handleDelete = () => {
    methods.reset({ achievements: "" });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formContainer">
          <h2 className="title">Summary</h2>
          <Controller
            name="achievements"
            control={control}
            render={({ field }) => (
              <textarea
                {...field}
                className={`${errors.achievements && stylesSummary.error_form_input} ${stylesSummary.form_input}`}
                rows="10"
                style={{
                  border: !isEdit
                    ? "none"
                    : errors.achievements
                      ? "1px solid red"
                      : "1px solid #ccc",
                  resize: !isEdit ? "none" : "vertical",
                }}
                readOnly={!isEdit}
                placeholder="Enter your achievements here"
              />
            )}
          />
          {errors.achievements && (
            <p className={stylesSummary.errorText}>{errors.achievements.message}</p>
          )}

          <div className={styles.buttonsContainer}>
            <button type="button" className={styles.button} onClick={handleEditToggle}>
              <div className={styles.editContainer}>
                <img src={editIcon} alt="Edit" />
                <div>EDIT</div>
              </div>
            </button>
            <button type="button" className={styles.button} onClick={handleDelete}>
              <div className={styles.deleteContainer}>
                <img src={deleteIcon} alt="Delete" />
                <div>DELETE</div>
              </div>
            </button>
          </div>
        </div>

        <FormNavigationButtons onBack={onToggleBack} isSaveDisabled={!isValid} />
      </form>
    </FormProvider>
  );
};

export default PreviewStep;
