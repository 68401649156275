import axios from "axios";
import { getToken } from "../utils/auth.utils";

const { REACT_APP_API_URL } = process.env;

export const getApiHeader =(isAuthorized=true) => ({
  "Content-Type": "application/json",
  ...(getToken() && isAuthorized && { Authorization: `Bearer ${localStorage.getItem("token")}` }),
});

export const axiosInstance = axios.create({
  baseURL: REACT_APP_API_URL,
  headers: getApiHeader(),
});
