import { format } from "date-fns";

export const getCVCopy = (receivedData) => {
  const currentUser = JSON.parse(localStorage.getItem("user"));

  const date = format(new Date(), "HH:mm:ss  dd/MM/yyyy");
  const data = {
    name: `${receivedData.name}(copy ${date})`,
    languages: receivedData.languages,
    country: receivedData.country,
    job_title: receivedData.job_title,
    years_of_experience: receivedData.years_of_experience,
    months_of_experience: receivedData.months_of_experience,
    summary: receivedData.summary,
    bug_tracking_and_pm: receivedData.bug_tracking_and_pm,
    devops_skills: receivedData.devops_skills,
    extra_skills: receivedData.extra_skills,
    design_patterns: receivedData.design_patterns,
    general_info: receivedData.general_info,
    skills: receivedData.skills,
    other_skills: receivedData.other_skills,
    educations: receivedData.educations,
    certifications: receivedData.certifications,
    projects: receivedData.projects,
    created_at: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
    creator_name: currentUser.name,
    creator_id: currentUser.sub,
  };

  return data;
};
