import React from "react";

export const Footer = () => {
  return (
    <div className="footer">
      <div style={{ color: "rgba(58, 53, 65, 0.68)" }}>
        {new Date().getFullYear()}, Made for{" "}
        <a
          style={{ color: "#9155FD", textDecoration: "none" }}
          href="https://jetsoftpro.com/"
          target="_blank">
          JetSoftPro
        </a>
      </div>
      <div style={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px", color: "#9155FD" }}>
        <a
          href="https://jetsoftpro.atlassian.net/wiki/spaces/JETCV/overview"
          target="_blank"
          style={{ color: "#9155FD", marginRight: "16px", textDecoration: "none" }}>
          Documentation
        </a>
        <a
          href="https://jetitsm.jetsoftpro.com"
          target="_blank"
          style={{ color: "#9155FD", textDecoration: "none" }}>
          Support
        </a>
      </div>
    </div>
  );
};
