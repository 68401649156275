import React from "react";
import PropTypes from "prop-types";
import NavbarLogin from "../../pages/Login/LoginComponent";
import NavbarLogout from "../NavbarLogout";
import logo from "../../assets/pictures/logo.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

const Header = ({ authenticated }) => {
  return (
    <div className="header">
      <div className="Logo">
        <Link to={ROUTES.home}>
          <img alt="logo" width="77px" height="21px" src={logo} />
        </Link>
      </div>
      {authenticated && <NavbarLogout />}
    </div>
  );
};

Header.propTypes = {
  authenticated: PropTypes.bool,
};

export { Header };
