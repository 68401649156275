export const monthData = [
  "Jan",
  "Feb",
  "March",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const yearData = () => {
  let current = new Date().getFullYear();
  let startYear = 1980;
  let allYears = [];
  while (startYear <= current) {
    allYears.push((startYear++).toString());
  }
  return allYears;
};