// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form .ExperienceStep_projectName__Jf2wQ {
  margin-bottom: 0;
}
form .ExperienceStep_switcherContainer__OdzyJ {
  margin-bottom: 24px;
}
form .ExperienceStep_switcherLabel__KVDDp {
  font-size: 14px;
  color: rgba(58, 53, 65, 0.6784313725);
}
form .ExperienceStep_switcherWork__U22oE {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -20px 0 24px 31px;
}
form .ExperienceStep_company__jRwwz {
  margin-bottom: 24px;
}

.ExperienceStep_styledTextarea__EP0ty {
  max-width: 100%;
  min-width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/CvSteps/ExperienceStep/ExperienceStep.module.scss"],"names":[],"mappings":"AAEI;EACI,gBAAA;AADR;AAII;EACI,mBAAA;AAFR;AAKI;EACI,eAAA;EACA,qCAAA;AAHR;AAMI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;AAJR;AAOI;EACI,mBAAA;AALR;;AAUA;EACI,eAAA;EACA,eAAA;AAPJ","sourcesContent":["\nform {\n    .projectName {\n        margin-bottom: 0;\n    }\n\n    .switcherContainer {\n        margin-bottom: 24px;\n    }\n\n    .switcherLabel {\n        font-size: 14px;\n        color: #3A3541AD;\n    }\n\n    .switcherWork {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        margin: -20px 0 24px 31px;\n    }\n\n    .company {\n        margin-bottom: 24px; \n    }\n\n}\n\n.styledTextarea {\n    max-width: 100%;\n    min-width: 100%;\n}  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectName": `ExperienceStep_projectName__Jf2wQ`,
	"switcherContainer": `ExperienceStep_switcherContainer__OdzyJ`,
	"switcherLabel": `ExperienceStep_switcherLabel__KVDDp`,
	"switcherWork": `ExperienceStep_switcherWork__U22oE`,
	"company": `ExperienceStep_company__jRwwz`,
	"styledTextarea": `ExperienceStep_styledTextarea__EP0ty`
};
export default ___CSS_LOADER_EXPORT___;
