import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ROUTES } from "./constants/routes";
import "./App.scss";
import { Header } from "./Components/Header/Header";
import { Footer } from "./Components/Footer/Footer";
import Multiform from "./pages/Multiform/Multiform";
import { Home } from "./pages/Home/Home.jsx";
import AllCvs from "./pages/AllCvs/AllCvs";
import MyCvs from "./pages/MyCvs/MyCvs.jsx";
import { PublicRoute } from "./hoc/PublicRoute";
import { PrivateRoute } from "./hoc/PrivateRoute";
import Download from "./Components/Download/Download";
import EmployeeСv from "./pages/EmployeeCv/EmployeeСv";
import { GlobalProvider } from "./context/Provider";

import LoginComponent from "./pages/Login/LoginComponent";
import {onRefreshToken} from "./utils/auth.utils";

export const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState(0); //{0: 'user', 1: 'lowAdmin', 2: 'fullAdmin'}

  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      const user = localStorage.getItem("user");
      if (!!user) {
        const userObj = JSON.parse(user);
        const expTime = userObj.exp;
        const now = Math.floor(Date.now() / 1000);
        if (now >= expTime) {
          onRefreshToken();
        }
        setUserRole(userObj.role)
      }
      setAuthenticated(true);
    }
  }, [window.location]);

  return (
    <GlobalProvider>
      <Header authenticated={authenticated} />
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return authenticated ? <Redirect to={ROUTES.home} /> : <Redirect to={ROUTES.login} />;
          }}
        />
        <PublicRoute
          authenticated={authenticated}
          exact
          component={LoginComponent}
          path={ROUTES.login}
        />
        <PublicRoute
            authenticated={authenticated}
            exact
            component={LoginComponent}
            path={ROUTES.authenticated}
        />
        <PrivateRoute
          authenticated={authenticated && userRole !==0}
          exact
          component={AllCvs}
          path={ROUTES.allcvs}
        />
        <PrivateRoute authenticated={authenticated} exact component={Home} path={ROUTES.home} />
        <PrivateRoute
          authenticated={authenticated}
          exact
          component={Download}
          path={ROUTES.download}
        />
        <PrivateRoute
          authenticated={authenticated}
          exact
          component={Multiform}
          path={ROUTES.steps}
        />
        <PrivateRoute authenticated={authenticated} exact component={MyCvs} path={ROUTES.mycvs} />
        {/*<PrivateRoute*/}
        {/*  authenticated={authenticated}*/}
        {/*  exact*/}
        {/*  component={TableTemplate}*/}
        {/*  path={ROUTES.table}*/}
        {/*/>*/}
        <PrivateRoute
          authenticated={authenticated}
          exact
          component={Download}
          path={`${ROUTES.mycvs}/:id`}
        />
        <PrivateRoute
          authenticated={authenticated}
          exact
          component={EmployeeСv}
          path={`${ROUTES.employeecv}`}
        />

        <Route path="*" element={<h1>Error 404 Page not found !!!</h1>} />
      </Switch>
      <Footer />
    </GlobalProvider>
  );
};