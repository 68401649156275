import React, { useState, useEffect } from "react";
import styles from "./LoginTemplate.module.scss";
import {Auth_service} from "../../services/auth.service";
import {
  setQueryParameters,
  getToken,
  getLoginURLLocal,
  code,
  session_state,
  state,
  client_info,
} from "../../utils/auth.utils";
import { Loader } from "../../Components/Loader/Loader";

const LoginComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (window.location.href.indexOf("/authenticated") > -1 && getToken() === null) {
      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      const session_state = params.get("session_state");
      const state = params.get("state");
      const client_info = params.get("client_info");
      setQueryParameters(code, session_state, state, client_info);
      login(false);
    }
  }, [window.location]);

  const login = async (getUrl = true) => {
    setIsLoading(true);
    getUrl && await Auth_service.getLoginURL();
    await Auth_service.getLoginToken(code(), session_state(), state(), client_info());
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={styles.loginContainer}>
          <div>
            <h1 className={styles.title}>JetCV</h1>
            <div>
              <button onClick={login} className={styles.loginButton}>
                Login with Office365
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginComponent;
