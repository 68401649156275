import { zodResolver } from "@hookform/resolvers/zod";
import { Chip, FormControl } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ControlledAutocomplete from "../../common/Form/ControlledAutocomplete/ControlledAutocomplete";
import { skillsSchema } from "./schemas/skillsSchema";
import styles from "./SkillsStep.module.scss";
import FormNavigationButtons from "../../common/Form/FormNavigationButtons/FormNavigationButtons";

const SkillsStep = ({
  updateData,
  handleSkills,
  handlePreviousSkills,
  previousSkills,
  prLanguagesData,
}) => {
  const pageNum = 6;
  const sortedSkills = [
    { name: "extra_skills", placeholder: "Frameworks/Libraries" },
    { name: "bug_tracking_and_pm", placeholder: "Bug Tracking/Project Management" },
    { name: "devops_skills", placeholder: "Software/CVS/Build Automation" },
    { name: "design_patterns", placeholder: "Design Patterns" },
    { name: "other_skills", placeholder: "Others" },
  ];

  const methods = useForm({
    defaultValues: {
      general_info: previousSkills.general_info || "",
      skills: previousSkills.skills || [],
      extra_skills: previousSkills.frameworks || "",
      bug_tracking_and_pm: previousSkills.bugs || "",
      devops_skills: previousSkills.cvs || "",
      design_patterns: previousSkills.patterns || "",
      other_skills: previousSkills.other_skills || "",
    },
    resolver: zodResolver(skillsSchema),
  });

  const { handleSubmit, control, reset, watch } = methods;

  const [generalInfo, skills, extraSkills, bugTracking, devopsSkills, designPatterns, otherSkills] =
    watch([
      "general_info",
      "skills",
      "extra_skills",
      "bug_tracking_and_pm",
      "devops_skills",
      "design_patterns",
      "other_skills",
    ]);

  const onSubmit = (data) => {
    handleSkills(data);
    handlePreviousSkills(data);
    updateData(pageNum);
  };

  const onToggleBack = () => updateData(pageNum - 2);

  useEffect(() => {
    if (previousSkills) {
      reset({
        general_info: previousSkills.general_info || "",
        skills: previousSkills.skills || [],
        extra_skills: previousSkills.frameworks || "",
        bug_tracking_and_pm: previousSkills.bugs || "",
        devops_skills: previousSkills.cvs || "",
        design_patterns: previousSkills.patterns || "",
        other_skills: previousSkills.other_skills || "",
      });
    }
  }, [previousSkills, reset]);

  const isGeneralInfoFieldDisabled =
    extraSkills ||
    (skills && skills.length) ||
    bugTracking ||
    devopsSkills ||
    designPatterns ||
    otherSkills;

  const isSaveDisabled =
    !generalInfo &&
    !(skills && skills.length) &&
    !extraSkills &&
    !bugTracking &&
    !devopsSkills &&
    !designPatterns &&
    !otherSkills;

  const errorMessage = isSaveDisabled
    ? "Please fill in General Info or at least one other field."
    : "";

  return (
    <FormProvider {...methods}>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formContainer">
            <h2 className="title">Skills *</h2>
            <p className={errorMessage ? styles.errorMessage : styles.explanation}>
              {errorMessage || "Fill General Info or fields below"}
            </p>

            <Controller
              name="general_info"
              control={control}
              render={({ field }) => (
                <textarea
                  {...field}
                  className={`${styles.skillTextarea} form_input`}
                  placeholder="General info"
                  disabled={isGeneralInfoFieldDisabled}
                />
              )}
            />

            <div className={styles.divider}>or</div>

            <FormControl fullWidth>
              <ControlledAutocomplete
                disabled={generalInfo}
                name="skills"
                label="Programming Languages/Technologies"
                options={prLanguagesData || []}
                freeSolo
                multiple
                // getOptionLabel={(option) => option?.label || ""}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip key={index} label={option} {...getTagProps({ index })} />
                  ))
                }
              />
            </FormControl>

            {sortedSkills.map((item) => (
              <Controller
                key={item.name}
                name={item.name}
                control={control}
                render={({ field }) => (
                  <textarea
                    {...field}
                    className={`${styles.skillTextarea} form_input`}
                    placeholder={item.placeholder}
                    disabled={generalInfo}
                  />
                )}
              />
            ))}
          </div>

          <FormNavigationButtons
            onBack={onToggleBack}
            isSaveDisabled={isSaveDisabled}
            onSave={handleSubmit(onSubmit)}
          />
        </form>
      </div>
    </FormProvider>
  );
};

export default SkillsStep;
