// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginTemplate_loginContainer__eIKHS {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 200px;
  background-color: white;
  border-top: 3px solid orange;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  box-shadow: 0 5px 5px -3px rgba(58, 53, 65, 0.2), 0px 8px 10px 1px rgba(58, 53, 65, 0.14), 0px 3px 14px 2px rgba(58, 53, 65, 0.12);
}
.LoginTemplate_loginContainer__eIKHS .LoginTemplate_title__aAj3R {
  font-size: 30px;
  font-weight: 700;
  color: gray;
  text-align: center;
}
.LoginTemplate_loginContainer__eIKHS .LoginTemplate_loginButton__1i4cu {
  background-color: #ffa500;
  padding: 8px 15px;
  font-size: 16px;
  color: white;
  border-radius: 5px;
  border: none;
  margin-top: 15px;
  cursor: pointer;
}
.LoginTemplate_loginContainer__eIKHS .LoginTemplate_loginButton__1i4cu:hover {
  background-color: #b47504;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/LoginTemplate.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;EACA,aAAA;EACA,uBAAA;EACA,4BAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,kBAAA;EACA,gCAAA;EACA,kBAAA;EACA,kIAAA;AACF;AAEE;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;AAAJ;AAGE;EACE,yBAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;AADJ;AAGE;EACE,yBAAA;AADJ","sourcesContent":[".loginContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 30%;\n  height: 200px;\n  background-color: white;\n  border-top: 3px solid orange;\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  margin-right: -50%;\n  transform: translate(-50%, -50%);\n  border-radius: 5px;\n  box-shadow: 0 5px 5px -3px rgba(58, 53, 65, 0.2), 0px 8px 10px 1px rgba(58, 53, 65, 0.14),\n    0px 3px 14px 2px rgba(58, 53, 65, 0.12);\n\n  .title {\n    font-size: 30px;\n    font-weight: 700;\n    color: gray;\n    text-align: center;\n  }\n\n  .loginButton {\n    background-color: #ffa500;\n    padding: 8px 15px;\n    font-size: 16px;\n    color: white;\n    border-radius: 5px;\n    border: none;\n    margin-top: 15px;\n    cursor: pointer;\n  }\n  .loginButton:hover{\n    background-color: #b47504;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginContainer": `LoginTemplate_loginContainer__eIKHS`,
	"title": `LoginTemplate_title__aAj3R`,
	"loginButton": `LoginTemplate_loginButton__1i4cu`
};
export default ___CSS_LOADER_EXPORT___;
