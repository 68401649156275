import { useState, useEffect } from "react";

const useScrollButtons = () => {
  const [scrollBtnUp, setScrollBtnUp] = useState(false);
  const [scrollBtnDown, setScrollBtnDown] = useState(false);

  useEffect(() => {
    const scrollFunction = () => {
      if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 20) {
        setScrollBtnDown(false);
      } else {
        setScrollBtnDown(true);
      }

      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        setScrollBtnUp(true);
      } else {
        setScrollBtnUp(false);
      }
    };

    window.addEventListener("scroll", scrollFunction);

    return () => {
      window.removeEventListener("scroll", scrollFunction);
    };
  }, []);

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const scrollToBottom = () => {
    document.body.scrollTop = document.body.offsetHeight;
    document.documentElement.scrollTop = document.body.offsetHeight;
  };

  return { scrollBtnUp, scrollBtnDown, scrollToTop, scrollToBottom };
};

export default useScrollButtons;
