import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Tabs from "../../Components/Tab/Tab";
import EditIcon from "../../assets/pictures/Edit.png";
import CopyIcon from "../../assets/pictures/copy.png";
import WordIcon from "../../assets/pictures/docxDownload.png";
import GetAppIcon from "@mui/icons-material/GetApp";
import MaterialTable from "@material-table/core";
import styles from "./AllCvs.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getAllCV } from "../../redux/actions/allCvAction";
import ErrorModal from "../../Components/errorModal/errorModal";
import { handleDocXDownload } from "../../utils/downloadDocX/downloadDoc";
import { downloadPDFFile } from "../../utils/downloadPDF.utils";
import { onRefreshToken } from "../../utils/auth.utils";
import { CV_service } from "../../services/cv.service";
import { duplicateCv, getCvById } from "../../redux/reducers/cvReducer";

const AllCvs = () => {
  const userRole = JSON.parse(localStorage.getItem("user")).role;
  // {0: 'user', 1: 'lowAdmin', 2: 'fullAdmin'}
  const shouldRedirect = false;
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const { total, items } = useSelector((state) => state.allCvReducer.list);
  const { error } = useSelector((state) => state.allCvReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const columns = [
    { title: "Full Name", field: "name", align: "left", emptyValue: () => <em>null</em> },
    { title: "Position", field: "job_title", emptyValue: () => <em>null</em> },
    { title: "Experience", field: "years_of_experience", emptyValue: () => <em>null</em> },
    { title: "Created", field: "created_at", emptyValue: () => <em>null</em> },
    { title: "Created by", field: "creator_name", emptyValue: () => <em>null</em> },
    { title: "Updated by", field: "updated_by", emptyValue: () => <em>null</em> },
  ];

  const offsetPage = page * pageSize;

  useEffect(() => {
    if (shouldRedirect) {
      history.push("/table");
    }
    dispatch(getAllCV(pageSize, offsetPage));
  }, [page, pageSize]);

  useEffect(() => {
    if (error !== null) {
      setErrorModal(true);
      setErrorText(error.message);
    }
  }, [error]);

  const getCvByID = async (id) => {
    const result = await dispatch(getCvById(id));

    if (getCvById.fulfilled.match(result)) {
      const edit = result.payload;
      history.push({
        pathname: "/steps",
        state: { edit },
      });
    }
  };

  const handleDuplicateCv = async (cvId) => {
    const result = await dispatch(duplicateCv(cvId));
    if (duplicateCv.fulfilled.match(result)) {
      await dispatch(getAllCV(pageSize, offsetPage));
    }
  };

  return (
    <div className={styles.allCvs}>
      {errorModal && (
        <ErrorModal onOpen={errorModal} onClose={() => setErrorModal(false)} text={errorText} />
      )}
      <Tabs active={2} />
      <div className={styles.tablecontainer}>
        <MaterialTable
          onRowClick={(e, selectedRow) => {
            setSelectedRow(selectedRow.tableData.id);
            let id = selectedRow.tableData.id;
            let userCv = items.find((x) => x.id === id);
            history.push({
              pathname: "/employeeСv",
              state: { userCv },
            });
          }}
          onPageChange={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          columns={columns}
          data={items}
          totalCount={total}
          page={page}
          title=""
          editable={
            userRole === 1
              ? {}
              : {
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      CV_service.deleteCV(oldData.id)
                        .then((res) => {
                          if (res.status === 403) {
                            onRefreshToken();
                          } else {
                            dispatch(getAllCV());
                            resolve();
                          }
                        })
                        .catch((error) => {
                          console.error("Error:", error);
                        });
                    }),
                }
          }
          actions={[
            {
              icon: () => <img className={styles.cv} src={CopyIcon} />,
              tooltip: "Copy",
              onClick: (e, data) => {
                handleDuplicateCv(data.id);
              },
            },
            userRole === 2 && {
              icon: () => <img className={styles.cv} src={EditIcon} />,
              tooltip: "Edit",
              onClick: (e, data) => {
                getCvByID(data.id);
              },
            },
            {
              icon: GetAppIcon,
              tooltip: "Save pdf",
              onClick: (e, data) => {
                downloadPDFFile(data.id, data.name);
              },
            },
            {
              icon: () => <img className={styles.cv} src={WordIcon} />,
              tooltip: "Save .doc file",
              onClick: async (e, data) => {
                const cv = await dispatch(getCvById(data.id));
                handleDocXDownload(cv.payload);
              },
            },
          ]}
          options={{
            sorting: false,
            search: true,
            searchText: "",
            searchFieldAlignment: "right",
            // searchFieldVariant: "",
            paging: true,
            pageSizeOptions: [5, 10, 15],
            showFirstLastPageButtons: false,
            addRowPosition: "first",
            rowStyle: (rowData) => ({
              backgroundColor: selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
            }),
            actionsColumnIndex: -1,
          }}
        />
      </div>
    </div>
  );
};
export default AllCvs;
