import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import ControlledAutocomplete from "../../common/Form/ControlledAutocomplete/ControlledAutocomplete";
import ControlledInput from "../../common/Form/ControlledInput/ControlledInput";
import { educationFormSchema } from "./schemas/educationSchema";
import { monthData, yearData } from "../../../constants/date";
import ScrollButtons from "../../common/ScrollButtons/ScrollButtons";
import FormNavigationButtons from "../../common/Form/FormNavigationButtons/FormNavigationButtons";

const EducationStep = ({
  updateData,
  handleEducation,
  handlePreviousEducation,
  previousEducation,
  degreeData,
}) => {
  const pageNum = 3;
  const defaultEducation = {
    school_name: "",
    degree: "",
    field_of_studies: "",
    graduate_date_month: "",
    graduate_date_year: "",
  };
  const methods = useForm({
    defaultValues: {
      educations: previousEducation || [{ ...defaultEducation }],
    },
    resolver: zodResolver(educationFormSchema),
  });

  const { control, handleSubmit, reset } = methods;
  const { fields, append, remove } = useFieldArray({ control, name: "educations" });

  useEffect(() => {
    if (previousEducation.length) {
      reset({ educations: previousEducation });
    } else {
      reset({ educations: [{ ...defaultEducation }] });
    }
  }, [previousEducation, reset]);

  const submit = (data) => {
    handleEducation(data.educations);
    handlePreviousEducation(data.educations);
    updateData(pageNum);
  };

  const onToggleBack = () => updateData(pageNum - 2);

  const handleAddNewEducation = () => {
    append({ ...defaultEducation });
  };

  const handleRemoveOneEducation = (event, index) => {
    event.preventDefault();
    remove(index);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        <div>
          <ScrollButtons />
          <div className="formContainer">
            {fields.map((field, index) => (
              <React.Fragment key={field.id}>
                <h2 className="title">Education {index + 1}</h2>

                <div className="label" key={index}>
                  For example Taras Shevchenko National University of Kyiv
                </div>
                <ControlledInput
                  name={`educations.${index}.school_name`}
                  className="form_input"
                  placeholder="School Name"
                  style={{ marginBottom: "24px" }}
                />

                <ControlledAutocomplete
                  name={`educations.${index}.degree`}
                  label="Degree"
                  options={degreeData}
                />

                <ControlledInput
                  name={`educations.${index}.field_of_studies`}
                  className="form_input"
                  placeholder="Field of Studies"
                  style={{ marginBottom: "24px" }}
                />

                <div className="dateBlock">
                  <div className="dateLabel">Graduate Date</div>
                  <div className="dateSelect">
                    <div className="dateInput">
                      <ControlledAutocomplete
                        name={`educations.${index}.graduate_date_month`}
                        label="Month"
                        options={monthData}
                      />
                    </div>
                    <div className="dateInput">
                      <ControlledAutocomplete
                        name={`educations.${index}.graduate_date_year`}
                        label="Year"
                        options={yearData()}
                      />
                    </div>
                  </div>
                </div>
                <div className="cancelContainerExperience">
                  {fields.length > 1 && (
                    <button
                      className="buttonCancel"
                      onClick={(event) => handleRemoveOneEducation(event, index)}>
                      Remove
                    </button>
                  )}
                </div>
              </React.Fragment>
            ))}

            <div className="buttonsContainerExperience">
              <button
                className="buttonSave"
                type="button"
                onClick={handleAddNewEducation}
                style={{ marginBottom: "24px" }}>
                Add New Education
              </button>
            </div>
          </div>

          <FormNavigationButtons onBack={onToggleBack} />
        </div>
      </form>
    </FormProvider>
  );
};

export default EducationStep;
