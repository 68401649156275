import { z } from "zod";

export const experienceSchema = z
  .object({
    experiences: z
      .array(
        z.object({
          company: z.string().optional().nullable(),
          name: z.string().optional().nullable(),
          under_nda: z.boolean().optional().nullable(),
          industry: z.string().optional().nullable(),
          country: z
            .object({
              name: z.string().optional().nullable(),
              code: z.string().optional().nullable(),
              flag: z.string().optional().nullable(),
            })
            .optional()
            .nullable(),
          job_title: z.string().optional().nullable(),
          start_date_month: z.string().optional().nullable(),
          start_date_year: z.string().optional().nullable(),
          end_date_month: z.string().optional().nullable(),
          end_date_year: z.string().optional().nullable(),
          currently_work_here: z.boolean().optional().nullable(),
          description: z.string().optional().nullable(),
          responsibilities: z.string().optional().nullable(),
          achievements: z.string().optional().nullable(),
          tools_and_technologies: z.string().optional().nullable(),
        })
      )
      .optional()
      .nullable(),
  })
  .optional();
